import { Helmet, HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  Cs,
  DataSyntax,
  Eff,
  FTPP,
  MVVD,
  NLP,
  PLLM_BG,
  PLLM_ILLUS,
  Sec,
} from "../../assets";
const helmetData = new HelmetData({});
export default function PLLM() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence | Products | Private Lage Language Models
        </title>
        <link rel="canonical" href="/pllm" />
        <meta
          name="description"
          content="PLLM (Private Large Language Model) is an advanced natural language processing (NLP) system designed to use [AI]'s DataSyntax codes for masking sensitive enterprise data. Acting as an intermediary between third-party LLMs and enterprise data, PLLM ensures secure and efficient data processing. Third-party LLMs are fine-tuned to respond with DataSyntax codes, which the PLLM system then parses and converts into data and visualisations."
        />
        <meta
          name="keywords"
          content="Private Large Language Model, PLLM, enterprise, Fine-Tuning PLLM for Precision,Masters Vast Vocabularies & Domains, customised model, business, specific analytics data, private, secure, sustainable, successful AI programs, data"
        />
      </Helmet>
      <main className=" pllm-bg text-white pb-[150px]   bg-[#03050B]">
        <div className="container py-[100px]  lg:py-[120px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] justify-between ">
          <div className="w-full xl:px-3 mx-auto flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[600px] flex flex-col gap-[30px] md:gap-[36px]">
              <h3 className="md:text-[40px] text-[28px] font-[700] about-h leading-[150%]">
                PLLM
              </h3>
              <p className="w-full text-base md:text-[19px] leading-[180%] font-[300]">
                <span className="font-bold">
                  {" "}
                  PLLM (Private Large Language Model)
                </span>{" "}
                is an advanced natural language processing (NLP) system designed
                to use [AI]'s DataSyntax codes for masking sensitive enterprise
                data. Acting as an intermediary between third-party LLMs and
                enterprise data, PLLM ensures secure and efficient data
                processing.
              </p>
            </div>
            <div className="lg:mr-[51px]">
              <img src={PLLM_BG} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container linear-line h-[1px]  w-full opacity-30" />
        <div className="container flex flex-col py-10 md:py-[100px] gap-5 md:gap-[90px] lg:gap-[20px] justify-between ">
          <div className="w-full xl:px-3 mx-auto flex md:flex-row-reverse flex-col md:gap-[5%] gap-[50px] lg:items-center justify-between  ">
            <div className="w-full max-w-[590px] flex flex-col gap-[30px] md:gap-[36px]">
              <h3 className="md:text-[40px] text-[28px] text-poppingBlue font-[700] leading-[150%]">
                NLP for Secure Data Analytics
              </h3>
              <p className="w-full max-w-[565px] leading-[200%] blurry-text">
                From the outset, [AI] has prioritised the development of a
                secure NLP system for enterprise data analytics. As a vital
                component of the [AI] Platform, this NLP system is used for
                extracting and classifying specific enterprise data entities
                such as dates, metrics, dimensions, and segments.
              </p>
              <Link to="/demo">
                <button className="py-3 px-6 flex justify-center items-center bg-poppingBlue text-white rounded-lg gap-2">
                  <span> Request a Demo</span>
                </button>
              </Link>
            </div>
            <div className="lg:ml-10">
              <img src={NLP} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container flex flex-col py-10 md:py-[100px] gap-5 md:gap-[90px] lg:gap-[20px] justify-between ">
          <div className="w-full xl:px-3 mx-auto flex md:flex-row flex-col md:gap-[5%] gap-[50px] lg:items-center justify-between  ">
            <div className="w-full max-w-[590px] flex flex-col gap-[30px] md:gap-[36px]">
              <h3 className="md:text-[40px] text-[28px] text-poppingBlue font-[700] leading-[150%]">
                Data Syntax
              </h3>
              <p className="w-full max-w-[565px] leading-[200%] blurry-text">
                DataSyntax codes are derived from the NLP system's
                representation of various enterprise data components, including
                dates, metrics, dimensions, segments, KPIs, targets,
                visualisations, and more. With DataSyntax, developers can
                securely utilise LLMs to effortlessly access, analyse, and
                present enterprise data without risking exposure of sensitive
                information.
              </p>
              <Link to="/demo">
                <button className="py-3 px-6 flex justify-center items-center bg-poppingBlue text-white rounded-lg gap-2">
                  <span> Request a Demo</span>
                </button>
              </Link>
            </div>
            <div className="">
              <img src={DataSyntax} alt="illustrarion" />
            </div>
          </div>
        </div>
        <h1 className="font-bold text-[28px] text-center">Features</h1>
        <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-[60px] gap-5 md:gap-[39px] justify-between ">
          <div className="p-[1px] border-gradient-alt !rounded-[20px]">
            <div className="w-full mx-auto flex flex-col items-center justify-between gap-[30px] py-[57px] px-[25px] bg-[#05060b] rounded-[20px] h-full">
              <h1 className="text-poppingBlue text-center text-[28px] font-[700]">
                Comprehensive Data Representation
              </h1>
              <p className="text-center">
                Provides a wide range of components representing the enterprise
                dataset, facilitating detailed data analysis and visualisation.
              </p>
            </div>
          </div>
          <div className="p-[1px] border-gradient-mid !rounded-[20px]">
            <div className="w-full mx-auto flex flex-col items-center justify-between gap-[30px] py-[78px] px-[25px] bg-[#05060b] rounded-[20px] h-full">
              <h1 className="text-poppingBlue text-center text-[28px] font-[700]">
                Enhanced Security
              </h1>
              <p className="text-center">
                Mask sensitive enterprise data using DataSyntax codes, ensuring
                data privacy and enhanced security.
              </p>
            </div>
          </div>
          <div className="p-[1px] border-gradient !rounded-[20px]">
            <div className="w-full mx-auto flex flex-col items-center justify-between gap-[30px] py-[57px] px-[25px] bg-[#05060b] rounded-[20px] h-full">
              <h1 className="text-poppingBlue text-center text-[28px] font-[700]">
                Efficient Intermediary System
              </h1>
              <p className="text-center">
                Seamlessly connect third-party LLMs with enterprise data,
                enabling secure and efficient data processing.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
