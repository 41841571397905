import { useForm } from "@formspree/react";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { toast } from "react-hot-toast";

export default function TrainingForm() {
  const [areaInterest, setAreaInterest] = useState("");
  const [otherInterest, setOtherInterest] = useState("");
  const [emailListPermission, setEmailListPermission] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [state, handleSubmit, reset] = useForm("mdknknyj");

  const handleSuccess = (message) => {
    toast.dismiss();
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  if (state.succeeded) {
    handleSuccess("Request sucessfully sent, We wil be in touch shortly");
    reset();
  }
  const handleChange = (newValue) => {
    setPhoneNumber(newValue);
  };

  return (
    <div
      className="container py-[28px] md:py-[64px] flex flex-col gap-6 partners-lines relative"
      id="your-interest"
    >
      <h1 className="max-w-[728px] mx-auto text-center text-[32px] gradient-header md:text-[40px] font-semibold leading-[150%]">
        Register Your Interest
      </h1>
      <form
        method="POST"
        onSubmit={handleSubmit}
        className="w-full max-w-[600px] mx-auto"
      >
        <div>
          <label className="text-poppingBlue text-[23px]" htmlFor="fullName">
            Full Name
          </label>
          <input
            required
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Enter full name"
            className="form-input bg-transparent"
          />
        </div>
        <div>
          <label className="text-poppingBlue text-[23px]" htmlFor="email">
            Email
          </label>
          <input
            required
            type="email"
            id="email"
            name="email"
            placeholder="Enter email"
            className="form-input bg-transparent"
          />
        </div>
        <div className="my-3">
          <label className="text-poppingBlue text-[23px]" htmlFor="phone">
            Phone Number
          </label>
          <MuiTelInput
            value={phoneNumber}
            onChange={handleChange}
            required
            type="tel"
            id="phone"
            name="phone"
            placeholder="Enter phone number"
            className="w-full bg-transparent"
          />
        </div>
        <div className="field">
          <label
            className="text-poppingBlue text-[23px]"
            htmlFor="areaInterest"
          >
            Area Interested In
          </label>
          <div className="control">
            <div className=" select relative">
              <select
                className="cursor-pointer form-input bg-dark"
                required
                id="areaInterest"
                name="Area Interested In"
                value={areaInterest}
                onChange={(e) => setAreaInterest(e.target.value)}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Data Analytics Training">
                  Data Analytics Training
                </option>
                <option value="Adobe Analytics">Adobe Analytics</option>
                <option value="Google Analytics (GA4)">
                  Google Analytics (GA4)
                </option>
                <option value="Prompt Engineering for Data Analytics">
                  Prompt Engineering for Data Analytics
                </option>
                <option value="Other">Other</option>
              </select>
              <div className="pointer-events-none absolute top-[20px] right-[10px] flex items-center px-2 text-white ">
                <svg
                  className="fill-current h-8 w-8 "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M8 6l4 4 4-4h-8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {areaInterest === "Other" && (
          <div>
            <label
              className="text-poppingBlue text-[23px]"
              htmlFor="otherInterest"
            >
              Specify Other Interest
            </label>
            <input
              required
              type="text"
              id="otherInterest"
              name="otherInterest"
              placeholder="Specify other interest"
              className="form-input bg-transparent"
              value={otherInterest}
              onChange={(e) => setOtherInterest(e.target.value)}
            />
          </div>
        )}
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="emailListPermission"
            name="emailListPermission"
            checked={emailListPermission}
            onChange={() => setEmailListPermission(!emailListPermission)}
          />
          <label
            className="blurry-text text-[13px]"
            htmlFor="emailListPermission"
          >
            I agree to be added to the email list for notifications on upcoming
            training sessions
          </label>
        </div>
        <div className="flex flex-col sm:flex-row gap-3 w-max mx-auto mt-4">
          <button
            type="reset"
            className="w-max mx-auto mt-4 flex justify-center items-center px-[56px] py-4 text-white bg-dark rounded-xl"
          >
            Clear All
          </button>
          <button
            disabled={state.submitting}
            type="submit"
            role="form"
            className="w-max mx-auto mt-4 flex justify-center items-center px-[56px] py-4 bg-poppingBlue rounded-xl"
          >
            {state.submitting ? "Submitting" : "Submit"}
          </button>
        </div>
        {/* <div className="blurry-text  mt-8">
          <p>
            Note: Businesses, please contact us to enroll multiple employees on
            the notification list.
          </p>
        </div> */}
      </form>
    </div>
  );
}
