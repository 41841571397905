import { Helmet, HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AL, DAS, DA_HERO, DSW, FEAT, NLP } from "../../assets";
import { useState } from "react";
const helmetData = new HelmetData({});

const init = {
  title: "",
  desc: "",
  icon: "",
};
const products = [
  {
    title: "Analytics Engine",
    desc: "At the heart of the [AI] Platform is the Analytics Engine—a robust data analytics and predictive modeling system. It runs a comprehensive range of data analytics algorithms on enterprise data, delivering actionable insights.",
    icon: AL,
  },
  {
    title: "Data Language Model",
    desc: "The Data Language Model (DLM) is [AI]'s specialised small language model, trained specifically for data analytics. Acting as an intermediary between large language models and the Analytics Engine, it ensures precise and relevant data interpretations.",
    icon: DSW,
  },
];
export default function DataAssistant() {
  const [activeProduct, setActiveProduct] = useState(init);
  const [animationMode, setAnimationMode] = useState(false);

  const handleSetActiveProduct = (title, i) => {
    setAnimationMode(true);
    if (title === activeProduct.title) setActiveProduct(init);
    else setActiveProduct(products[i]);
  };
  const stopAnimation = () => {
    setAnimationMode(false);
    setActiveProduct(init);
  };
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Analytics Intelligence | Products | Data Assistant</title>
        <link rel="canonical" href="/data-assistant" />
        <meta
          name="description"
          content="Data Assistant is a cutting-edge generative AI solution designed to revolutionise data analytics. It empowers business users, regardless of their data science proficiency, to leverage data effectively and enhance their decision-making processes. Focused on each user's KPIs, it provides timely insights to optimise conversion goals."
        />
        <meta
          name="keywords"
          content="Data Assistant, Analytics Engine, KPI, Key Performance Indicators, Natural
                  Language Processing, NLP, Data Language Model, Goals Management Agents, Personalised data analysis generative AI solution, data analytics, empower business users, data science proficiency, leverage data, decision-making processes"
        />
      </Helmet>
      <main className=" data-bg text-white pb-[150px]   bg-[#03050B]">
        <div className="container py-[100px]  lg:py-[150px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] justify-between ">
          <div className="w-full xl:px-3 mx-auto flex md:flex-row flex-col md:gap-[5%] gap-[50px] lg:items-center justify-between  ">
            <div className="w-full max-w-[600px] flex flex-col gap-[30px] md:gap-[36px]">
              <h3 className="md:text-[40px] text-[28px] font-[700] gradient-h1 leading-[150%]">
                Data Assistant
              </h3>
              <p className="w-full text-base md:text-[19px] leading-[180%] font-[300]">
                <span className="font-bold">Data Assistant</span> is a
                cutting-edge generative AI solution designed to revolutionise
                data analytics. It empowers business users, regardless of their
                data science proficiency, to leverage data effectively and
                enhance their decision-making processes. Focused on each user's
                KPIs, it provides timely insights to optimise conversion goals.
              </p>
              <div className="flex  items-center gap-8">
                <Link to="/demo">
                  <button className="py-3 px-6 flex justify-center items-center bg-poppingBlue text-white rounded-lg gap-2">
                    <span> Request a Demo</span>
                  </button>
                </Link>
                <a
                  href="https://dataassistant.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Learn More</p>
                </a>
              </div>
            </div>
            <div className="">
              <img src={DA_HERO} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div
          className="container flex flex-col py-[40px] lg:py-[72px] gap-[64px] "
          id="more"
        >
          <div className="flex flex-col md:flex-row-reverse md:gap-[5%] gap-[50px] justify-between lg:items-center">
            <div>
              <div>
                <h1 className="text-[20px] font-[600] leading-[150%]">
                  STRUCTURES
                </h1>
                <div className="linear-line h-[1px] mt-[6px] w-full max-w-[570px]" />
              </div>
              <div
                className={`${
                  animationMode
                    ? activeProduct.title === products[0].title
                      ? "opacity-100"
                      : "opacity-30"
                    : "opacity-100"
                } w-full max-w-[570px] my-[46px]`}
              >
                <h1
                  className="cursor-pointer text-poppingBlue font-[700] text-[26px] md:text-[33px] leading-[150%] mb-[6px]"
                  onClick={() => handleSetActiveProduct(products[0].title, 0)}
                >
                  {products[0].title}
                </h1>
                <p className="blurry-text" onClick={stopAnimation}>
                  {products[0].desc}
                </p>
                <div className="linear-line h-[1px] mt-[46px] w-full -rotate-180" />
              </div>
              <div
                className={`${
                  animationMode
                    ? activeProduct.title === products[1].title
                      ? "opacity-100"
                      : "opacity-30"
                    : "opacity-100"
                } w-full max-w-[570px]`}
              >
                <h1
                  className="cursor-pointer text-poppingBlue font-[700] text-[26px] md:text-[33px] leading-[150%] mb-[6px]"
                  onClick={() => handleSetActiveProduct(products[1].title, 1)}
                >
                  {products[1].title}
                </h1>
                <p className="blurry-text" onClick={stopAnimation}>
                  {products[1].desc}
                </p>
              </div>
            </div>
            <div className="lg:ml-[34px]">
              <img src={DAS} alt="illustrations" />
            </div>
          </div>
        </div>
        <div className="container linear-line h-[1px] mt-[6px] w-full opacity-40" />
        <div className="container flex flex-col py-[40px] lg:py-[72px] gap-[64px] ">
          <div className="flex flex-col md:flex-row md:gap-[5%] gap-[50px] justify-between lg:items-center">
            <div className="lg:ml-[27px]">
              <h1 className="text-[20px] font-[600] leading-[150%]">
                Features
              </h1>
              <div className={`my-[36px] w-full max-w-[583px]`}>
                <h1 className="cursor-pointer text-poppingBlue font-[700] text-[26px] md:text-[33px] leading-[150%] mb-[6px]">
                  Build a Data-Driven Culture
                </h1>
                <p className="blurry-text">
                  Identify key strategic metrics as KPIs and assign them to
                  relevant users and teams. Foster a data-driven culture with a
                  proactive Data Assistant, which works alongside each user to
                  monitor KPIs and provide real-time performance updates.
                </p>
              </div>
              <div className={` w-full max-w-[583px]`}>
                <h1 className="cursor-pointer text-poppingBlue font-[700] text-[26px] md:text-[33px] leading-[150%] mb-[6px]">
                  Personalised Data Analysis
                </h1>
                <p className="blurry-text">
                  Your Data Assistant acts as a personalised data analyst,
                  focused on your specific KPIs and goals. It can respond to
                  fundamental analysis inquiries, oversee metric performance,
                  send timely notifications, and offer recommendations for
                  optimisation.
                </p>
              </div>
            </div>
            <div className="lg:mr-[91.88px]">
              <img src={FEAT} alt="illustrations" />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
