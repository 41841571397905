import { call, chat } from "../assets";

export const navLinks = [
  {
    label: "Products",
    links: [
      {
        label: "[AI] Platform",
        link: "/ai-platform",
      },
      {
        label: "Data Assistant",
        link: "/data-assistant",
      },
      {
        label: "PLLM",
        link: "/pllm",
      },
    ],
  },
  {
    label: "Solutions",
    links: [
      {
        label: "Retail & E-commerce",
        link: "/retail",
      },
      {
        label: "Fintech & Finance",
        link: "/fintech",
      },
      {
        label: "Marketing & Advertising",
        link: "/marketing",
      },
    ],
  },
  {
    label: "Resources",
    links: [
      {
        label: "Digital Analytics Support",
        link: "/digital-analytics-support",
      },
      {
        label: "Digital Analytics Training",
        link: "/digital-analytics-training",
      },
      // {
      //   label: "Guides",
      //   link: "/guides",
      // },
      {
        label: "Blog",
        link: "/blog",
      },
      // {
      //   label: "Events",
      //   link: "/events",
      // },
    ],
  },
];

export const footerLinks = [
  {
    label: "PRODUCT",
    links: [
      {
        label: "[AI] Platform",
        link: "/ai-platform",
      },
      {
        label: "Data Assistant",
        link: "/data-assistant",
      },
      {
        label: "PLLM",
        link: "/pllm",
      },
      {
        label: "Request a Demo",
        link: "/demo",
      },
    ],
  },
  {
    label: "COMPANY",
    links: [
      {
        label: "About us",
        link: "/company",
      },
      {
        label: "Contact Us",
        link: "/company#contact-us",
      },
      {
        label: "Careers",
        link: "/company#careers",
      },
      {
        label: "Faqs",
        link: "/#faqs",
      },
    ],
  },
  {
    label: "LEGAL",
    links: [
      {
        label: "Legal information",
        link: "/privacy",
      },
      {
        label: "Privacy",
        link: "/privacy",
      },
      {
        label: "Cookies",
        link: "/",
      },
      {
        label: "",
        link: "/",
      },
    ],
  },
  {
    label: "CONTACT",
    links: [
      {
        label: "info@analyticsintelligence.com",
        link: "mailto:info@analytics intelligence.com",
      },
      {
        label: "sales@analyticsintelligence.com",
        link: "mailto:sales@analytics intelligence.com",
      },
      {
        label: "support@analyticsintelligence.com",
        link: "mailto:support@analyticsintelligence.com",
      },
      {
        label: "careers@analyticsintelligence.com",
        link: "mailto:careers@analyticsintelligence.com",
      },
    ],
  },
];

export const questions = [
  "What is a RAG platform?",
  "Capabilities of Data Assitant and AI platorm?",
  "What solutions do you offer?",
];

export const aIPlatformQuestions = [
  "How does [AI] integrate data?",
  "What skills enhance AI assistant?",
  "How does KPI monitoring work?",
];

export const dataAssistantQuestions = [
  "What does Data Assistant do?",
  "How does Analytics Engine work?",
  "What is Data Language Model?",
];

export const pllmQuestions = [
  "How does PLLM secure data?",
  "How does DataSyntax improve privacy?",
  "What does DataSyntax represent?",
];

export const retailQuestions = [
  "How does [AI] boost retail marketing?",
  "What insights come from customer data?",
  "How are customers segmented and targeted?",
];

export const fintechQuestions = [
  "How does AI improve financial services?",
  "What tasks can be automated in fintech?",
  "How does the platform enhance decision-making?",
];

export const marketingQuestions = [
  "How does AI enhance marketing strategies?",
  "What alerts can be set for campaign performance?",
  "How are insights shared within the team?",
];

export const digitalAnalyticsQuestions = [
  "How does [AI] optimize digital performance?",
  "What is included in insight reporting?",
  "How do we ensure data accuracy?",
];

export const digitalAnalyticsTrainingQuestions = [
  "What does the digital analytics training cover?",
  "How can I master Adobe Analytics?",
  "What skills are taught in prompt engineering?",
];

export const aboutUsQuestions = [
  "What is the mission of [AI] Analytics Intelligence?",
  "How did [AI] Analytics Intelligence begin?",
  "What core values guide [AI] Analytics Intelligence?",
];

export const privacyPolicyQuestions = [
  "How is personal information collected and used?",
  "What security measures protect personal information?",
  "How does [AI] Analytics comply with privacy regulations?",
];

export const actions = [
  {
    action: "chat",
    desc: "Instant message with our help assistant [AI]Chat",
    icon: chat,
  },
  {
    action: "call",
    desc: "Schedule a Call with a customer care Representative",
    icon: call,
  },
];

export const countries = [
  "Country",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const languages = [
  {
    label: "en",
    value: "english",
  },
  {
    label: "fr",
    value: "french",
  },
  {
    label: "ar",
    value: "arabic",
  },
  {
    label: "es",
    value: "spanish",
  },
];

export const timezones = ["Time zone", "UTC", "GMT", "PST", "WAT"];

// English Translations (default)
export const english = {
  questions: [
    "What is a RAG platform?",
    "Capabilities of Data Assistant and AI platform?",
    "What solutions do you offer?",
  ],
  aiPlatformQuestions: [
    "How does AI integrate data?",
    "What skills enhance AI assistant?",
    "How does KPI monitoring work?",
  ],
  dataAssistantQuestions: [
    "What does Data Assistant do?",
    "How does Analytics Engine work?",
    "What is Data Language Model?",
  ],
  pllmQuestions: [
    "How does PLLM secure data?",
    "How does DataSyntax improve privacy?",
    "What does DataSyntax represent?",
  ],
  retailQuestions: [
    "How does AI boost retail marketing?",
    "What insights come from customer data?",
    "How are customers segmented and targeted?",
  ],
  fintechQuestions: [
    "How does AI improve financial services?",
    "What tasks can be automated in fintech?",
    "How does the platform enhance decision-making?",
  ],
  marketingQuestions: [
    "How does AI enhance marketing strategies?",
    "What alerts can be set for campaign performance?",
    "How are insights shared within the team?",
  ],
  digitalAnalyticsQuestions: [
    "How does AI optimize digital performance?",
    "What is included in insight reporting?",
    "How do we ensure data accuracy?",
  ],
  digitalAnalyticsTrainingQuestions: [
    "What does the digital analytics training cover?",
    "How can I master Adobe Analytics?",
    "What skills are taught in prompt engineering?",
  ],
  aboutUsQuestions: [
    "What is the mission of AI Analytics Intelligence?",
    "How did AI Analytics Intelligence begin?",
    "What core values guide AI Analytics Intelligence?",
  ],
  privacyPolicyQuestions: [
    "How is personal information collected and used?",
    "What security measures protect personal information?",
    "How does AI Analytics comply with privacy regulations?",
  ],
  actions: [
    {
      action: "chat",
      desc: "Instant message with our help assistant [AI]Chat",
      icon: chat,
    },
    {
      action: "call",
      desc: "Schedule a Call with a customer care Representative",
      icon: call,
    },
  ],
  infoTexts: {
    moreInfo:
      "For more info on our product offerings, engage with [AI] Chat, or speak to a customer care representative.",
    scheduleCall:
      "For specific requests, kindly fill out this form to schedule a call with a customer care representative.",
    customerCareRep: "[AI] Customer Care Representative",
    scheduleMessage: "Please schedule a call with our customer representative.",
    feedbackThankYou: "Thank you for your feedback.",
    callRequestSuccess:
      "Call Request Successful, check your email for the call details.",
  },
  countries: [
    "Country",
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    /*...*/ "Zimbabwe",
  ],
};

// Spanish Translations
export const spanish = {
  questions: [
    "¿Qué es una plataforma RAG?",
    "Capacidades del Asistente de Datos y la plataforma de IA?",
    "¿Qué soluciones ofrece?",
  ],
  aiPlatformQuestions: [
    "¿Cómo integra datos la IA?",
    "¿Qué habilidades mejoran el asistente de IA?",
    "¿Cómo funciona el monitoreo de KPI?",
  ],
  dataAssistantQuestions: [
    "¿Qué hace el Asistente de Datos?",
    "¿Cómo funciona el motor de análisis?",
    "¿Qué es el modelo de lenguaje de datos?",
  ],
  pllmQuestions: [
    "¿Cómo asegura PLLM los datos?",
    "¿Cómo mejora DataSyntax la privacidad?",
    "¿Qué representa DataSyntax?",
  ],
  retailQuestions: [
    "¿Cómo impulsa la IA el marketing minorista?",
    "¿Qué información se obtiene de los datos de los clientes?",
    "¿Cómo se segmentan y se dirigen los clientes?",
  ],
  fintechQuestions: [
    "¿Cómo mejora la IA los servicios financieros?",
    "¿Qué tareas se pueden automatizar en fintech?",
    "¿Cómo mejora la toma de decisiones la plataforma?",
  ],
  marketingQuestions: [
    "¿Cómo mejora la IA las estrategias de marketing?",
    "¿Qué alertas se pueden configurar para el rendimiento de la campaña?",
    "¿Cómo se comparten los conocimientos dentro del equipo?",
  ],
  digitalAnalyticsQuestions: [
    "¿Cómo optimiza la IA el rendimiento digital?",
    "¿Qué se incluye en los informes de información?",
    "¿Cómo garantizamos la precisión de los datos?",
  ],
  digitalAnalyticsTrainingQuestions: [
    "¿Qué cubre la formación en análisis digital?",
    "¿Cómo puedo dominar Adobe Analytics?",
    "¿Qué habilidades se enseñan en la ingeniería de prompts?",
  ],
  aboutUsQuestions: [
    "¿Cuál es la misión de AI Analytics Intelligence?",
    "¿Cómo comenzó AI Analytics Intelligence?",
    "¿Qué valores fundamentales guían AI Analytics Intelligence?",
  ],
  privacyPolicyQuestions: [
    "¿Cómo se recopila y utiliza la información personal?",
    "¿Qué medidas de seguridad protegen la información personal?",
    "¿Cómo cumple AI Analytics con las regulaciones de privacidad?",
  ],
  actions: [
    {
      action: "chat",
      desc: "Mensaje instantáneo con nuestro asistente de ayuda [AI]Chat",
      icon: chat,
    },
    {
      action: "call",
      desc: "Programar una llamada con un representante de atención al cliente",
      icon: call,
    },
  ],
  infoTexts: {
    moreInfo:
      "Para obtener más información sobre nuestras ofertas de productos, interactúe con [AI] Chat o hable con un representante de atención al cliente.",
    scheduleCall:
      "Para solicitudes específicas, complete este formulario para programar una llamada con un representante de atención al cliente.",
    customerCareRep: "[AI] Representante de atención al cliente",
    scheduleMessage:
      "Por favor, programe una llamada con nuestro representante de atención al cliente.",
    feedbackThankYou: "Gracias por sus comentarios.",
    callRequestSuccess:
      "Solicitud de llamada exitosa, revise su correo electrónico para los detalles de la llamada.",
  },
  countries: [
    "País",
    "Afganistán",
    "Islas Åland",
    "Albania",
    "Argelia",
    /*...*/ "Zimbabue",
  ],
};

// French Translations
export const french = {
  questions: [
    "Qu'est-ce qu'une plateforme RAG?",
    "Capacités de l'assistant de données et de la plateforme IA?",
    "Quelles solutions proposez-vous?",
  ],
  aiPlatformQuestions: [
    "Comment l'IA intègre-t-elle les données?",
    "Quelles compétences améliorent l'assistant IA?",
    "Comment fonctionne le suivi des KPI?",
  ],
  dataAssistantQuestions: [
    "Que fait l'assistant de données?",
    "Comment fonctionne le moteur d'analyse?",
    "Qu'est-ce que le modèle de langage des données?",
  ],
  pllmQuestions: [
    "Comment PLLM sécurise-t-il les données?",
    "Comment DataSyntax améliore-t-il la confidentialité?",
    "Que représente DataSyntax?",
  ],
  retailQuestions: [
    "Comment l'IA booste-t-elle le marketing de détail?",
    "Quelles informations proviennent des données client?",
    "Comment les clients sont-ils segmentés et ciblés?",
  ],
  fintechQuestions: [
    "Comment l'IA améliore-t-elle les services financiers?",
    "Quelles tâches peuvent être automatisées dans la fintech?",
    "Comment la plateforme améliore-t-elle la prise de décision?",
  ],
  marketingQuestions: [
    "Comment l'IA améliore-t-elle les stratégies marketing?",
    "Quelles alertes peuvent être définies pour les performances des campagnes?",
    "Comment les insights sont-ils partagés au sein de l'équipe?",
  ],
  digitalAnalyticsQuestions: [
    "Comment l'IA optimise-t-elle les performances digitales?",
    "Que comprend le rapport d'information?",
    "Comment assurons-nous la précision des données?",
  ],
  digitalAnalyticsTrainingQuestions: [
    "Que couvre la formation en analyse digitale?",
    "Comment puis-je maîtriser Adobe Analytics?",
    "Quelles compétences sont enseignées en ingénierie de prompts?",
  ],
  aboutUsQuestions: [
    "Quelle est la mission d'AI Analytics Intelligence?",
    "Comment AI Analytics Intelligence a-t-elle commencé?",
    "Quelles valeurs fondamentales guident AI Analytics Intelligence?",
  ],
  privacyPolicyQuestions: [
    "Comment les informations personnelles sont-elles collectées et utilisées?",
    "Quelles mesures de sécurité protègent les informations personnelles?",
    "Comment AI Analytics respecte-t-elle les réglementations sur la confidentialité?",
  ],
  actions: [
    {
      action: "chat",
      desc: "Message instantané avec notre assistant d'aide [AI]Chat",
      icon: chat,
    },
    {
      action: "call",
      desc: "Planifier un appel avec un représentant du service client",
      icon: call,
    },
  ],
  infoTexts: {
    moreInfo:
      "Pour plus d'informations sur nos offres de produits, engagez-vous avec [AI] Chat ou parlez à un représentant du service client.",
    scheduleCall:
      "Pour des demandes spécifiques, veuillez remplir ce formulaire pour planifier un appel avec un représentant du service client.",
    customerCareRep: "[AI] Représentant du service client",
    scheduleMessage:
      "Veuillez planifier un appel avec notre représentant client.",
    feedbackThankYou: "Merci pour vos commentaires.",
    callRequestSuccess:
      "Demande d'appel réussie, consultez votre e-mail pour les détails de l'appel.",
  },
  countries: [
    "Pays",
    "Afghanistan",
    "Îles Åland",
    "Albanie",
    "Algérie",
    /*...*/ "Zimbabwe",
  ],
};

// Arabic Translations
export const arabic = {
  questions: [
    "ما هي منصة RAG؟",
    "ما هي قدرات مساعد البيانات ومنصة الذكاء الاصطناعي؟",
    "ما الحلول التي تقدمونها؟",
  ],
  aiPlatformQuestions: [
    "كيف يدمج الذكاء الاصطناعي البيانات؟",
    "ما المهارات التي تعزز مساعد الذكاء الاصطناعي؟",
    "كيف يعمل مراقبة مؤشرات الأداء الرئيسية (KPI)؟",
  ],
  dataAssistantQuestions: [
    "ما الذي يفعله مساعد البيانات؟",
    "كيف يعمل محرك التحليلات؟",
    "ما هو نموذج لغة البيانات؟",
  ],
  pllmQuestions: [
    "كيف يؤمن PLLM البيانات؟",
    "كيف يحسن DataSyntax الخصوصية؟",
    "ما الذي يمثله DataSyntax؟",
  ],
  retailQuestions: [
    "كيف يعزز الذكاء الاصطناعي التسويق بالتجزئة؟",
    "ما هي الرؤى التي تأتي من بيانات العملاء؟",
    "كيف يتم تقسيم واستهداف العملاء؟",
  ],
  fintechQuestions: [
    "كيف يحسن الذكاء الاصطناعي الخدمات المالية؟",
    "ما المهام التي يمكن أتمتتها في التكنولوجيا المالية (Fintech)؟",
    "كيف تعزز المنصة اتخاذ القرار؟",
  ],
  marketingQuestions: [
    "كيف يعزز الذكاء الاصطناعي استراتيجيات التسويق؟",
    "ما التنبيهات التي يمكن تعيينها لأداء الحملة؟",
    "كيف تتم مشاركة الرؤى داخل الفريق؟",
  ],
  digitalAnalyticsQuestions: [
    "كيف يقوم الذكاء الاصطناعي بتحسين الأداء الرقمي؟",
    "ماذا يتضمن تقرير الرؤى؟",
    "كيف نضمن دقة البيانات؟",
  ],
  digitalAnalyticsTrainingQuestions: [
    "ما الذي تغطيه تدريبات التحليلات الرقمية؟",
    "كيف يمكنني إتقان Adobe Analytics؟",
    "ما المهارات التي يتم تدريسها في هندسة المطالبات؟",
  ],
  aboutUsQuestions: [
    "ما هي مهمة AI Analytics Intelligence؟",
    "كيف بدأت AI Analytics Intelligence؟",
    "ما هي القيم الأساسية التي توجه AI Analytics Intelligence؟",
  ],
  privacyPolicyQuestions: [
    "كيف يتم جمع واستخدام المعلومات الشخصية؟",
    "ما هي التدابير الأمنية التي تحمي المعلومات الشخصية؟",
    "كيف تمتثل AI Analytics للأنظمة المتعلقة بالخصوصية؟",
  ],
  actions: [
    { action: "chat", desc: "رسالة فورية مع مساعدنا [AI]Chat", icon: chat },
    { action: "call", desc: "جدولة مكالمة مع ممثل رعاية العملاء", icon: call },
  ],
  infoTexts: {
    moreInfo:
      "لمزيد من المعلومات حول عروض منتجاتنا، تواصل مع [AI] Chat، أو تحدث إلى ممثل رعاية العملاء.",
    scheduleCall:
      "للطلبات المحددة، يرجى ملء هذا النموذج لتحديد موعد مكالمة مع ممثل رعاية العملاء.",
    customerCareRep: "[AI] ممثل رعاية العملاء",
    scheduleMessage: "يرجى تحديد موعد مكالمة مع ممثل خدمة العملاء.",
    feedbackThankYou: "شكرًا لك على ملاحظاتك.",
    callRequestSuccess:
      "تم طلب المكالمة بنجاح، يرجى التحقق من بريدك الإلكتروني للحصول على تفاصيل المكالمة.",
  },
  countries: [
    "البلد",
    "أفغانستان",
    "جزر آلاند",
    "ألبانيا",
    "الجزائر",
    /*...*/ "زيمبابوي",
  ],
};

export const l = {
  arabic,
  english,
  french,
  spanish,
};

export const prefilledQuestions = (pathname, langKey) => {
  const lang = l[langKey];

  if (!lang) {
    console.error(`Language ${langKey} not found!`);
    return [];
  }

  switch (pathname) {
    case "/ai-platform":
      return lang?.aiPlatformQuestions;
    case "/data-assistant":
      return lang?.dataAssistantQuestions;
    case "/pllm":
      return lang?.pllmQuestions;
    case "/retail":
      return lang?.retailQuestions;
    case "/marketing":
      return lang?.marketingQuestions;
    case "/fintech":
      return lang?.fintechQuestions;
    case "/digital-analytics-support":
      return lang?.digitalAnalyticsQuestions;
    case "/digital-analytics-training":
      return lang?.digitalAnalyticsTrainingQuestions;
    case "/company":
      return lang?.aboutUsQuestions;
    case "/privacy":
      return lang?.privacyPolicyQuestions;
    default:
      return lang?.questions;
  }
};
