import React from "react";

export const CallIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4166 11.1324L13.7966 10.7549C14.3216 10.2333 15.1391 10.1258 15.8107 10.4908L17.4024 11.3566C18.4249 11.9124 18.6507 13.2749 17.8516 14.0691L16.6682 15.2458C16.3666 15.5458 15.9932 15.7641 15.5632 15.8041C14.5699 15.8966 12.5257 15.8466 10.1141 14.4349L13.4166 11.1324ZM8.49157 6.23578L8.73073 5.99744C9.3199 5.41244 9.37573 4.47244 8.86157 3.78578L7.81157 2.38328C7.1749 1.53328 5.94573 1.42078 5.21657 2.14578L3.90823 3.44578C3.5474 3.80578 3.30573 4.27078 3.3349 4.78744C3.38907 5.73744 3.7124 7.47078 5.12573 9.60078L8.49157 6.23578Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M10.0524 9.58082C7.5974 7.13999 8.48573 6.24082 8.49157 6.23499L5.12573 9.60165C5.68157 10.4383 6.4049 11.3358 7.34573 12.2717C8.29573 13.2167 9.22907 13.9175 10.1141 14.435L13.4166 11.1333C13.4166 11.1333 12.5149 12.03 10.0524 9.58165"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4166 11.1324L13.7966 10.7549C14.3216 10.2333 15.1391 10.1258 15.8107 10.4908L17.4024 11.3566C18.4249 11.9124 18.6507 13.2749 17.8516 14.0691L16.6682 15.2458C16.3666 15.5458 15.9932 15.7641 15.5632 15.8041C14.5699 15.8966 12.5257 15.8466 10.1141 14.4349L13.4166 11.1324ZM8.49157 6.23578L8.73073 5.99744C9.3199 5.41244 9.37573 4.47244 8.86157 3.78578L7.81157 2.38328C7.1749 1.53328 5.94573 1.42078 5.21657 2.14578L3.90823 3.44578C3.5474 3.80578 3.30573 4.27078 3.3349 4.78744C3.38907 5.73744 3.7124 7.47078 5.12573 9.60078L8.49157 6.23578Z"
        fill="#FFFFFFCC"
      />
      <path
        opacity="0.6"
        d="M10.0524 9.58082C7.5974 7.13999 8.48573 6.24082 8.49157 6.23499L5.12573 9.60165C5.68157 10.4383 6.4049 11.3358 7.34573 12.2717C8.29573 13.2167 9.22907 13.9175 10.1141 14.435L13.4166 11.1333C13.4166 11.1333 12.5149 12.03 10.0524 9.58165"
        fill="#FFFFFFCC"
      />
    </svg>
  );
};
