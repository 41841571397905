import React, { useEffect, useState } from "react";
import { Typewriter } from "react-simple-typewriter";

export default function Type() {
  const [loopCount, setLoopCount] = useState(0);
  const [activeWord, setActiveWord] = useState("");

  const headers = [
    {
      "to enhance data-driven business decisions": "Generative AI RAG Platform",
    },
    {
      "to enhance productivity with data insights": "Generative AI Assistant",
    },
    {
      "to protect sensitive enterprise information":
        "Generative AI Private Models",
    },
  ];
  const heads = [
    "Generative AI RAG Platform",
    "Generative AI Assistant",
    "Generative AI Private Models",
  ];
  const words = [
    "to enhance data-driven business decisions",
    "to enhance productivity with data insights",
    "to protect sensitive enterprise information",
  ];
  const totalLoops = 1;
  const typeSpeed = 70;
  const deleteSpeed = 50;
  const delaySpeed = 6000;
  const delaySpeed2 = 6000;

  useEffect(() => {
    if (loopCount < totalLoops) {
      setTimeout(() => {
        setLoopCount((prevLoopCount) => prevLoopCount + 1);
      }, delaySpeed);
    }
  }, [loopCount]);

  const handleType = (count) => {
    setActiveWord(words[count % words.length]);
  };

  const header = headers.find((t) => Object.keys(t).includes(activeWord));
  const headerText = header ? header[activeWord] : "";

  return (
    <div className="App md:text-[55px] max-w-[800px] text-[32px] md:min-h-[250px] sm:min-h-[100px] min-h-[200px]">
      <h1 className={`font-[600]`}>
        <p className="relative">
          <span className={` opacity-0 transition-all duration-1000  w-full`}>
            {heads[2]}
          </span>
          <span
            className={`${
              heads[0] === headerText ? "opacity-100" : "opacity-0"
            } transition-all duration-1000  w-full absolute bottom-0 left-0`}
          >
            {heads[0]}
          </span>
          <span
            className={`${
              heads[1] === headerText ? "opacity-100" : "opacity-0"
            } transition-all duration-1000  w-full absolute bottom-0 left-0`}
          >
            {heads[1]}
          </span>
          <span
            className={`${
              heads[2] === headerText ? "opacity-100" : "opacity-0"
            } transition-all duration-1000  w-full absolute bottom-0 left-0`}
          >
            {heads[2]}
          </span>
        </p>
        {/* <br /> */}
        <p style={{ color: "#0073FF", fontWeight: 400 }} id="wording">
          <Typewriter
            words={words}
            loop={totalLoops}
            cursor
            cursorStyle="|"
            typeSpeed={typeSpeed}
            deleteSpeed={deleteSpeed}
            delaySpeed={delaySpeed2}
            onType={handleType}
          />
        </p>
      </h1>
    </div>
  );
}
