import { Helmet, HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AOE, CS, ECE, FintechH, OCE } from "../../assets";
const helmetData = new HelmetData({});

export default function Fintech() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence | Solutions | Fintech & Finance
        </title>
        <link rel="canonical" href="/fintech" />
        <meta
          name="description"
          content="We are an AI company with experience in Fintech and finance
            projects. We can help transform your financial services with data
            and AI, and unlock new opportunities for growth and efficiency.
            Artificial intelligence is revolutionizing the financial industry,
            enabling new products, services, and business models. Whether you
            want to optimise your risk management or automate your processes, we
            can help you achieve your goals."
        />
        <meta
          name="keywords"
          content="Fintech, Customise Analytics, Automate Operations, Drive Precision Decision-Making, leading companies, data-driven future, Analytics ready NLP systems, Large Language Models, Digital Analytics Stack, business questions, data-driven decision-making, data growth, information overload, middle layer analytics solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className="finance-bg  text-white pb-[150px]   bg-[#03050B]">
        <div className="container py-[100px]  lg:py-[150px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] justify-between xl:px-3">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[597px] flex flex-col gap-3">
              <h3 className="md:text-[40px] text-[28px] font-[700] gradient-h1 leading-[150%]">
                Fintech & Finance
              </h3>
              <p className="w-full max-w-[597px] text-[16px] leading-[180%] blurry-text">
                Whether you're a multinational bank or a fintech startup, our
                adaptable solution caters to your unique needs. Predict customer
                behavior, assess risks accurately, and personalise services
                effortlessly. Powered by advanced AI algorithms, our platform
                analyses data to uncover indispensable insights.
              </p>
            </div>
            <div className="">
              <img src={FintechH} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container py-[20px] lg:py-[76px] flex flex-col gap-4 lg:gap-[36px] ">
          <h2 className="text-[28px] max-w-[900px] mx-auto sm:text-center gradient-h1 md:text-[33px] font-[700] leading-[150%]">
            Transform Your Financial Services with Data and AI
          </h2>
          <p className="w-full max-w-[1160px] mx-auto sm:text-center text-[rgba(255,255,255,0.80)]">
            At [AI] Analytics Intelligence, we bring extensive experience in
            Fintech and finance projects. We help transform your financial
            services with data and AI, unlocking new opportunities for growth
            and efficiency. Artificial intelligence is revolutionizing the
            financial industry, enabling new products, services, and business
            models. Whether you want to optimise your risk management or
            automate processes, we can help you achieve your goals.
          </p>
        </div>
        <div className="container bg-[#03050B] py-[30px] lg:py-[64px] mt-10">
          <img src={CS} alt="team" />
        </div>
        <div className="container py-[60px]  lg:py-[96px] lg:pb-[204px]   ">
          <div className="w-full flex md:flex-row flex-col md:gap-[5%] gap-[30px]  justify-between  ">
            <div className="w-full max-w-[407px] flex flex-col gap-6">
              <div className="flex gap-2 items-center">
                <div className="border border-poppingBlue h-max p-2 rounded-lg">
                  <img src={OCE} alt="icon" />
                </div>
                <h3 className="w-full text-[20px] md:text-[23px] text-poppingBlue font-[600] leading-[150%] opacity-80">
                  Customise Analytics and Reporting for Operations
                </h3>
              </div>
              <p className="w-full leading-[150%] blurry-text">
                Our solution offers fintech companies the tools to elevate their
                capabilities and redefine their data-driven strategies.
              </p>
            </div>
            <div className="w-full max-w-[407px] flex flex-col gap-6">
              <div className="flex gap-2 items-center">
                <div className="border border-poppingBlue h-max p-2 rounded-lg">
                  <img src={AOE} alt="icon" />
                </div>
                <h3 className="w-full text-[20px] md:text-[23px] text-poppingBlue font-[600] leading-[150%] opacity-80">
                  Automate Operations for Enhanced Efficiency
                </h3>
              </div>
              <p className="w-full leading-[150%] blurry-text">
                Improve operational efficiency and productivity by automating
                repetitive and manual tasks, such as document processing, fraud
                detection, and risk analysis.
              </p>
            </div>
            <div className="w-full max-w-[407px] flex flex-col gap-6">
              <div className="flex gap-2 items-center">
                <div className="border border-poppingBlue h-max p-2 rounded-lg">
                  <img src={ECE} alt="icon" />
                </div>
                <h3 className="w-full text-[20px] md:text-[23px] text-poppingBlue font-[600] leading-[150%] opacity-80">
                  Drive Precision Decision-Making
                </h3>
              </div>
              <p className="w-full leading-[150%] blurry-text">
                Powered by an advanced Analytics Engine, we provide unparalleled
                capabilities for analyzing vast amounts of financial data with
                precision and efficiency.
              </p>
            </div>
          </div>
        </div>
        <div className="company-bg-bottom ">
          <div className="container text-center flex flex-col gap-2  lg:py-[96px] ">
            <h1 className="gradient-header-alt md:text-[40px] font-semibold leading-[150%] text-[24px] tracking-[-0.4px]">
              Start Your Data-Driven Journey with [AI]
            </h1>
            <p className="px-2 w-full max-w-[900px] mx-auto blurry-text text-center font-manrope">
              Gain access to data-driven insights, automate processes, and
              deliver personalised solutions to your customers.
            </p>
            <Link to="/demo">
              <button className="w-max mx-auto mt-4 flex justify-center items-center px-[30px] py-4 bg-poppingBlue rounded-xl">
                Request a Demo
              </button>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
