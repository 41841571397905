import React from "react";

export const GlobeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_707_3754)">
        <path
          d="M1.3335 8.00004C1.3335 11.682 4.31816 14.6667 8.00016 14.6667C11.6822 14.6667 14.6668 11.682 14.6668 8.00004C14.6668 4.31804 11.6822 1.33337 8.00016 1.33337C4.31816 1.33337 1.3335 4.31804 1.3335 8.00004Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.66675 1.3667C8.66675 1.3667 10.6668 4.00003 10.6668 8.00003C10.6668 12 8.66675 14.6334 8.66675 14.6334M7.33342 14.6334C7.33342 14.6334 5.33342 12 5.33342 8.00003C5.33342 4.00003 7.33342 1.3667 7.33342 1.3667M1.75342 10.3334H14.2468M1.75342 5.6667H14.2468"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_707_3754">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
