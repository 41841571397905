import React from "react";

export const LikeIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M15.3334 6.66663C15.3334 6.313 15.1929 5.97387 14.9429 5.72382C14.6928 5.47377 14.3537 5.33329 14.0001 5.33329H9.78675L10.4267 2.28663C10.4401 2.21996 10.4467 2.14663 10.4467 2.07329C10.4467 1.79996 10.3334 1.54663 10.1534 1.36663L9.44675 0.666626L5.06008 5.05329C4.81342 5.29996 4.66675 5.63329 4.66675 5.99996V12.6666C4.66675 13.0202 4.80722 13.3594 5.05727 13.6094C5.30732 13.8595 5.64646 14 6.00008 14H12.0001C12.5534 14 13.0267 13.6666 13.2267 13.1866L15.2401 8.48663C15.3001 8.33329 15.3334 8.17329 15.3334 7.99996V6.66663ZM0.666748 14H3.33341V5.99996H0.666748V14Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.33317 6V14H0.666504V6H3.33317ZM5.99984 14C5.64622 14 5.30708 13.8595 5.05703 13.6095C4.80698 13.3594 4.6665 13.0203 4.6665 12.6667V6C4.6665 5.63333 4.81317 5.3 5.05984 5.06L9.4465 0.666666L10.1532 1.37333C10.3332 1.55333 10.4465 1.8 10.4465 2.07333L10.4265 2.28667L9.79317 5.33333H13.9998C14.3535 5.33333 14.6926 5.47381 14.9426 5.72386C15.1927 5.97391 15.3332 6.31304 15.3332 6.66667V8C15.3332 8.17333 15.2998 8.33333 15.2398 8.48667L13.2265 13.1867C13.0265 13.6667 12.5532 14 11.9998 14H5.99984ZM5.99984 12.6667H12.0198L13.9998 8V6.66667H8.13984L8.89317 3.12L5.99984 6.02V12.6667Z"
        fill="white"
        fill-opacity="0.8"
      />
    </svg>
  );
};
