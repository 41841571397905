import React from "react";

export const DislikeIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M0.666829 7.33337C0.666829 7.687 0.807305 8.02613 1.05735 8.27618C1.3074 8.52623 1.64654 8.66671 2.00016 8.66671H6.2135L5.5735 11.7134C5.56016 11.78 5.5535 11.8534 5.5535 11.9267C5.5535 12.2 5.66683 12.4534 5.84683 12.6334L6.5535 13.3334L10.9402 8.94671C11.1868 8.70004 11.3335 8.36671 11.3335 8.00004V1.33337C11.3335 0.979752 11.193 0.640614 10.943 0.390565C10.6929 0.140516 10.3538 4.00543e-05 10.0002 4.00543e-05H4.00016C3.44683 4.00543e-05 2.9735 0.333373 2.7735 0.813374L0.760162 5.51337C0.700162 5.66671 0.666829 5.82671 0.666829 6.00004V7.33337ZM15.3335 4.00543e-05H12.6668V8.00004H15.3335V4.00543e-05Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6668 10V2H15.3335V10H12.6668ZM10.0002 2C10.3538 2 10.6929 2.14048 10.943 2.39052C11.193 2.64057 11.3335 2.97971 11.3335 3.33333V10C11.3335 10.3667 11.1868 10.7 10.9402 10.94L6.5535 15.3333L5.84683 14.6267C5.66683 14.4467 5.5535 14.2 5.5535 13.9267L5.5735 13.7133L6.20683 10.6667H2.00016C1.64654 10.6667 1.3074 10.5262 1.05735 10.2761C0.807305 10.0261 0.666829 9.68696 0.666829 9.33333V8C0.666829 7.82667 0.700162 7.66667 0.760162 7.51333L2.7735 2.81333C2.9735 2.33333 3.44683 2 4.00016 2H10.0002ZM10.0002 3.33333H3.98016L2.00016 8V9.33333H7.86016L7.10683 12.88L10.0002 9.98V3.33333Z"
        fill="white"
        fill-opacity="0.8"
      />
    </svg>
  );
};
