import React from "react";

export const ChatIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33341 15H5.00008V18.4008L9.25091 15H13.3334C14.2526 15 15.0001 14.2525 15.0001 13.3333V6.66667C15.0001 5.7475 14.2526 5 13.3334 5H3.33341C2.41425 5 1.66675 5.7475 1.66675 6.66667V13.3333C1.66675 14.2525 2.41425 15 3.33341 15Z"
        fill="white"
      />
      <path
        d="M16.6667 1.66663H6.66667C5.7475 1.66663 5 2.41413 5 3.33329H15C15.9192 3.33329 16.6667 4.08079 16.6667 4.99996V11.6666C17.5858 11.6666 18.3333 10.9191 18.3333 9.99996V3.33329C18.3333 2.41413 17.5858 1.66663 16.6667 1.66663Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33341 15H5.00008V18.4008L9.25091 15H13.3334C14.2526 15 15.0001 14.2525 15.0001 13.3333V6.66667C15.0001 5.7475 14.2526 5 13.3334 5H3.33341C2.41425 5 1.66675 5.7475 1.66675 6.66667V13.3333C1.66675 14.2525 2.41425 15 3.33341 15Z"
        fill="#FFFFFFCC"
      />
      <path
        d="M16.6667 1.66663H6.66667C5.7475 1.66663 5 2.41413 5 3.33329H15C15.9192 3.33329 16.6667 4.08079 16.6667 4.99996V11.6666C17.5858 11.6666 18.3333 10.9191 18.3333 9.99996V3.33329C18.3333 2.41413 17.5858 1.66663 16.6667 1.66663Z"
        fill="#FFFFFFCC"
      />
    </svg>
  );
};
