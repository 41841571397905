import { useState } from "react";
import { ExpandCircleDown } from "@mui/icons-material";
const data = [
  {
    title: "What is [AI] Platform?",
    body: "A RAG platform for developing data analytics, conversational AI Agent, and Assistant systems for various enterprise use cases.",
  },
  {
    title: "What is Data Assistant?",
    body: "A generative AI solution designed to help users make data-driven decisions, providing timely insights focused on KPIs.",
  },
  {
    title: "Why choose [AI] Platform?",
    body: "[AI] Platform offers a reliable RAG infrastructure for data analytics applications, essential as generative AI becomes commonplace in enterprises.",
  },
  {
    title: "What is the Data Language Model?",
    body: "A specialised model trained for data analytics, working with our Analytics Engine to support conversational AI agents and large language models.",
  },
  {
    title: "What is PLLM?",
    body: "PLLM (Private Large Language Model) uses DataSyntax codes to mask sensitive data, ensuring privacy while delivering personalised data analysis.",
  },
  {
    title: "What solutions does the platform offer?",
    body: "Our AI-powered solutions cater to Digital Marketing, Fintech, E-Commerce, Retail, and more, providing tools for campaign performance, predicting customer behavior, gaining insights, and optimizing strategies.",
  },

  {
    title: "How is PLLM beneficial?",
    body: "PLLM excels in understanding specialised enterprise jargon and acronyms, aligns with industry terms, and offers a private and secure way to share enterprise data with external LLM providers.",
  },
  {
    title: "How does the company cater to different sectors?",
    body: "We address unique needs across financial, retail, e-commerce, digital marketing, and government sectors with our innovative AI solutions.",
  },
];
export default function Faq() {
  const [currentTab, setCurrentTab] = useState("");
  return (
    <div className="faqs flex flex-wrap gap-[24px] w-full justify-center mx-auto ">
      {data.map((d) => (
        <div
          className="expandable w-full max-w-[600px] h-max rounded-xl border border-[rgba(255,255,255,0.50)] p-5"
          key={d.title}
          onClick={() => {
            if (currentTab === d.title) {
              return setCurrentTab("");
            }
            setCurrentTab(d.title);
          }}
        >
          <div className="expandable-header">
            <p className="text-sm md:text-[20px] font-[500]">{d.title}</p>
            {currentTab === d.title ? (
              <ExpandCircleDown
                sx={{ color: "#0073FF" }}
                className="rotate-180 w-8 h-8"
              />
            ) : (
              <ExpandCircleDown sx={{ color: "#0073FF" }} className="w-8 h-8" />
            )}
          </div>
          <p
            className={` ${
              currentTab === d.title ? "expand" : ""
            } expandable-body`}
          >
            {d.body}
          </p>
        </div>
      ))}
    </div>
  );
}
