export default function Privacy() {
  return (
    <div className="legal-bg container py-[120px]  p-5 text-white">
      <h1 className="text-[33px] gradient-h1  mb-10">
        Security and Privacy Policy{" "}
      </h1>
      <h1 className="mt-4 mb-4 lg:mt-[64px] text-poppingBlue font-bold text-[28px]">
        Introduction
      </h1>
      <p className="blurry-text text-base leading-[150%]">
        Your privacy is important to [AI] Logic Technologies Limited and [AI]
        Analytics Intelligence Inc, both trading globally as [AI] Analytics
        Intelligence. This Privacy Policy (the "Policy") discloses our
        information practices, including our policies regarding the types of
        information that are gathered and tracked through our websites, desktop,
        and mobile apps (collectively referred to as "[AI] Analytics
        Intelligence Sites"), descriptions of how such information is used, and
        identification of the parties with whom the information may be shared.
        By voluntarily using the [AI] Analytics Intelligence Sites, you are
        indicating your consent to this Policy and agreement to be bound by its
        terms.
      </p>
      <h2 className="mt-4 lg:mt-[64px] mb-4 text-poppingBlue font-bold text-[28px] ">
        Information Collection and Use
      </h2>
      <h2 className="mt-4 mb-3 text-[19px] font-[600]">Personal Information</h2>
      <p className="blurry-text text-base leading-[150%]">
        We will need certain personally identifiable information ("Personal
        Information") from you. You may choose to provide us with Personal
        Information through the [AI] Analytics Intelligence Sites for purposes
        such as registering for our trainings. When you provide Personal
        Information to register on [AI] Analytics Intelligence Sites, such
        Personal Information will be used to provide you with access to our
        services. We may also use the Personal Information to help us understand
        who is using our products and services and to help us manage business
        development activities. If you inform us that you do not want us to use
        this Personal Information as a basis for further contact with you, we
        will respect your wishes.
      </p>
      <h2 className="mt-4 mb-3 text-[19px] font-[600]">
        Statistical Information
      </h2>
      <p className="blurry-text text-base leading-[150%]">
        In addition, we sometimes collect anonymous information from visits to
        the [AI] Analytics Intelligence Sites and services such as [AI] Academy,
        Poweredby[AI], Data Assistant, Virtual Analyst system, or mobile apps to
        help us provide better customer service and to make the [AI] Analytics
        Intelligence Sites as userfriendly as possible. For example, we keep
        track of the domains from which people visit, and we also measure
        visitor activity on [AI] Analytics Intelligence Sites. However, when we
        do so, we ensure the information remains anonymous. [AI] Analytics
        Intelligence may use this data to analyze trends and statistics and to
        help us provide better customer service. None of the information
        described in this paragraph is reviewed at an individual level.
      </p>
      <h2 className="mt-4 mb-3 text-[19px] font-[600]">
        Links to Third Party Sites
      </h2>
      <p className="blurry-text text-base leading-[150%]">
        The [AI] Analytics Intelligence Sites may contain links to other
        websites. [AI] Analytics Intelligence is not responsible for the privacy
        practices or the content of such websites. The links from an [AI]
        Analytics Intelligence Site do not imply that [AI] Analytics
        Intelligence endorses or has reviewed the third-party websites. We
        suggest contacting the operators of those websites directly for
        information on their privacy policies.
      </p>
      <h2 className="mt-4 mb-3 text-[19px] font-[600]">
        Our Disclosure of Your Personal Information and Other Information:
      </h2>
      <p className="blurry-text text-base leading-[150%]">
        [AI] Analytics Intelligence does not disclose or sell your Personal
        Information. We consider this information to be a vital part of our
        relationship with you. However, there are certain circumstances in which
        we may share your Personal Information with certain third parties
        without further notice to you, as set forth below:
      </p>
      <ul class="ml-5 list-disc pl-5 space-y-2 blurry-text text-base leading-[150%] mt-1">
        <li>
          <strong>Business Transfers: </strong>
          As we develop our business, we might sell or buy businesses or assets.
          In the event of a corporate sale, merger, reorganization, dissolution,
          or similar event, Personal Information may be part of the transferred
          assets.
        </li>
        <li>
          <strong> Related Companies: </strong>
          We may also share your Personal Information with our subsidiaries,
          affiliates, and other related companies for purposes consistent with
          this Policy.
        </li>
        <li>
          <strong> Agents, Consultants, and Related Third Parties: </strong>
          [AI] Analytics Intelligence, like many businesses, sometimes hires
          other companies to perform certain business-related functions.
          Examples of such functions include mailing information, maintaining
          databases, and processing payments. When we employ another company to
          perform a function of this nature, we only provide them with the
          information that they need to perform their specific function.
        </li>
      </ul>

      <h4 className="mt-4 lg:mt-[64px] mb-4 text-poppingBlue font-bold text-[28px]">
        Legal Disclaimer
      </h4>
      <p className="blurry-text text-base leading-[150%]">
        [AI] Analytics Intelligence reserves the right to disclose personal
        information to non-affiliated third parties for regulatory or reporting
        purposes as required by law, to protect the personal safety of users of
        an [AI] Analytics Intelligence Site or the public, to protect our rights
        or property, and when [AI] Analytics Intelligence believes that
        disclosure is necessary to protect our rights and/or to comply with any
        judicial and/or other proceedings, court orders, legal processes served,
        or pursuant to governmental, intergovernmental, and/or other regulatory
        bodies. [AI] Analytics Intelligence shall not be liable for misuse or
        loss of personal information and/or otherwise on [AI] Analytics
        Intelligence websites that [AI] Analytics Intelligence does not have
        access to or control over. [AI] Analytics Intelligence will not be
        liable for unlawful or unauthorized use of your personal information due
        to misuse or misplacement of your passwords, negligent or malicious
        intervention, and/or otherwise.
      </p>
      <h2 className="mt-4 lg:mt-[64px] mb-4 text-poppingBlue font-bold text-[28px]">
        Security
      </h2>
      <p className="blurry-text text-base leading-[150%]">
        Personal Information collected through the [AI] Analytics Intelligence
        Sites is kept in a secure database, and all reasonable precautions are
        taken to secure this data. However, no Internet or email transmission is
        ever fully secure or error-free. In particular, emails sent to or from
        an [AI] Analytics Intelligence Site may not be secure. Therefore, you
        should take special care in deciding what information you send to us via
        email. Please keep this in mind when disclosing any Personal Information
        to [AI] Analytics Intelligence via the Internet.
      </p>
      <h2 className="mt-4 lg:mt-[64px] mb-4 text-poppingBlue font-bold text-[28px]">
        Changes to Our Policy
      </h2>
      <p className="blurry-text text-base leading-[150%]">
        We may change our business and the [AI] Analytics Intelligence Sites
        from time to time. As a result, it may be necessary for us to make
        changes to this Policy. [AI] Analytics Intelligence reserves the right
        to update or modify this Policy at any time and from time to time
        without prior notice. Please review this Policy periodically, and
        especially before you provide any Personal Information. Your continued
        use of the [AI] Analytics Intelligence Site after any changes or
        revisions to this Policy shall indicate your agreement with the terms of
        such revised Policy.
      </p>
      <h2 className="mt-4 lg:mt-[64px] mb-4 text-poppingBlue font-bold text-[28px]">
        Access to Personal Information and Other Questions
      </h2>
      <p className="blurry-text text-base leading-[150%]">
        Under applicable regulations in the UK and the US, you have the right to
        obtain confirmation from [AI] Analytics Intelligence relating to the
        processing of your personal data, including the right to request a copy
        of all data processed about yourself. You also have the right to contact
        us to request information about the Personal Information we have
        collected from you in order to request the correction, modification, or
        deletion of such information. To make such a request and/or to pose any
        other questions regarding this Policy, please contact us via email at
        <a
          href="mailtto:privacy@analyticsintelligence.com"
          className="text-poppingBlue underline ML-2"
        >
          {" "}
          privacy@analyticsintelligence.com
        </a>
      </p>
      <h1 class="mt-4 lg:mt-[64px] mb-4 text-poppingBlue font-bold text-[28px]">
        Compliance with Cyber Security and Data Privacy Regulations
      </h1>

      <h2 class="mt-4 mb-3 text-[19px] font-[600]">
        Cyber Security Regulations (UK and US)
      </h2>
      <ul class="ml-5 list-disc pl-5 space-y-2 blurry-text text-base leading-[150%]">
        <li>
          <strong>National Cyber Strategy (UK):</strong> We are committed to
          making our AI systems secure by design, ensuring robust security is
          built into our systems from the development stage.
        </li>
        <li>
          <strong>AI Cyber Security Code of Practice (UK):</strong> We adhere to
          the voluntary code developed by the Department for Science, Innovation
          and Technology (DSIT), based on guidelines from the National Cyber
          Security Centre (NCSC), to protect end-users and ensure security
          throughout the lifecycle of our AI models and systems.{" "}
        </li>
        <li>
          <strong>Cyber Governance Code of Practice (UK):</strong> We ensure
          compliance with baseline expectations for cyber security standards as
          outlined in this code, relevant to the use of our generative AI tools.
        </li>
        <li>
          <strong>Federal Trade Commission (FTC) Guidelines (US):</strong> We
          ensure our AI systems comply with FTC guidelines, avoiding deceptive
          practices and adhering to consumer protection laws.
        </li>
        <li>
          <strong>
            National Institute of Standards and Technology (NIST) Framework
            (US):
          </strong>{" "}
          We follow NIST guidelines and standards to enhance the security and
          resilience of our AI systems.
        </li>
      </ul>

      <section class="mb-8">
        <h2 class="mt-4 mb-3 text-[19px] font-[600]">
          Data Privacy Regulations (UK and US)
        </h2>
        <ul class="ml-5 list-disc pl-5 space-y-2 blurry-text text-base leading-[150%]">
          <li>
            <strong>UK General Data Protection Regulation (UK GDPR):</strong> We
            process personal data in accordance with the principles of
            lawfulness, fairness, transparency, purpose limitation, data
            minimization, accuracy, storage limitation, and integrity and
            confidentiality.
          </li>
          <li>
            <strong>Data Protection Act 2018 (DPA 2018) (UK):</strong> We comply
            with the comprehensive framework for data protection in the UK,
            including specific provisions for law enforcement and intelligence
            services.
          </li>
          <li>
            <strong>ICO Consultation Series on Generative AI (UK):</strong> We
            stay informed and compliant with the guidance provided by the
            Information Commissioner's Office (ICO) regarding data protection
            laws as they apply to generative AI, including issues such as the
            lawful basis for training AI models, purpose limitation, accuracy,
            and data subject rights.
          </li>
          <li>
            <strong>California Consumer Privacy Act (CCPA) (US):</strong> We
            comply with the CCPA, ensuring California residents can exercise
            their rights over personal data, including knowing what data is
            collected, requesting deletion, and opting out of data sales.
          </li>
          <li>
            <strong>Virginia Consumer Data Protection Act (VCDPA) (US):</strong>{" "}
            We adhere to the VCDPA, providing Virginia residents with rights
            over their personal data and fulfilling our obligations to protect
            that data.
          </li>
          <li>
            <strong>Colorado Privacy Act (CPA) (US):</strong> We follow the CPA,
            ensuring Colorado residents' data rights are respected and
            implementing necessary data protection measures.
          </li>
        </ul>
      </section>

      <section class="mb-8">
        <h2 class="mt-4 mb-3 text-[19px] font-[600]">
          Federal and State AI-Specific Regulations (US)
        </h2>
        <ul class="ml-5 list-disc pl-5 space-y-2 blurry-text text-base leading-[150%]">
          <li>
            <strong>White House Executive Order on AI (US):</strong> We adhere
            to the principles outlined in this order, ensuring the responsible
            development and use of AI, including privacy, security, and
            fairness.
          </li>
          <li>
            <strong>Federal Legislation Proposals (US):</strong> We stay
            informed and compliant with proposed federal laws, including those
            requiring AI system licensing and the establishment of a federal
            regulatory agency for AI.
          </li>
          <li>
            <strong>State Legislation (US):</strong> We comply with various
            state regulations and executive orders, such as California's
            Executive Order on Generative AI, ensuring safety and privacy
            measures for AI systems used by the state.
          </li>
        </ul>
      </section>

      <section class="mb-8">
        <h2 class="mt-4 mb-3 text-[19px] font-[600]">
          Additional Considerations (UK and US)
        </h2>
        <ul class="ml-5 list-disc pl-5 space-y-2 blurry-text text-base leading-[150%]">
          <li>
            <strong>Intellectual Property Laws:</strong> We comply with
            intellectual property laws, recognizing that human authorship is
            essential for valid copyright claims, and ensuring the appropriate
            use of data for training AI models and the treatment of AI-generated
            content.
          </li>
          <li>
            <strong>Ethical and Bias Considerations:</strong> We conduct bias
            assessments and ensure transparency in AI decision-making processes
            to avoid perpetuating biases and promote ethical AI use.
          </li>
          <li>
            <strong>Confidentiality Obligations:</strong> We ensure that using
            generative AI tools does not breach confidentiality obligations,
            controlling and overseeing data use, and preventing inappropriate
            data sharing.
          </li>
        </ul>
      </section>

      <p className="blurry-text text-base leading-[150%]">
        By adhering to these regulations and guidelines in both the UK and the
        US, [AI] [AI] Analytics Intelligence ensures compliance and promotes the
        responsible use of AI technologies. For any questions or concerns
        regarding this Policy or our compliance with the above regulations,
        please contact us at
        <a
          href="mailtto:privacy@analyticsintelligence.com"
          className="text-poppingBlue underline ML-2"
        >
          {" "}
          privacy@analyticsintelligence.com
        </a>
      </p>
    </div>
  );
}
