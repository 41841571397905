import { ANALYTICS_KEY } from "./constants";

export async function fetcher(url, options) {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      api_key: ANALYTICS_KEY,
    },
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(errorResponse.message);
  }

  if (response.status === 204) {
    return null;
  } else {
    return response.json();
  }
}
