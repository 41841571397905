import { Helmet, HelmetData } from "react-helmet-async";
import Type from "./type";
import Partners, { Clients } from "./partners";
import { ArrowForward, NorthEast } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useCases } from "./constants";
import {
  ACT_INS,
  AI_Procut,
  CEA,
  DA_Product,
  DDA,
  Data_Growth,
  Embrace,
  Gain,
  PLLM_Product,
  RTI,
  SDP,
} from "../../assets";
import Faq from "./faq";
import { useState } from "react";

const helmetData = new HelmetData({});

const init = {
  title: "",
  desc: "",
  icon: "",
};
const products = [
  {
    title: "Data Assistant",
    desc: "is a generative AI solution for data analytics, empowering business users, regardless of their data science proficiency, to leverage data effectively. It enhances decision-making processes by providing timely insights and actionable recommendations tailored to each user's specific needs",
    icon: DA_Product,
  },
  {
    title: "[AI] Platform",
    desc: "is an innovative retrieval augmented generations (RAG) platform designed to develop data analytics, conversational AI Agents, and Assistant systems for diverse enterprise applications. The platform supports fine-tuning and training on enterprise data, allowing for customisation to specific turnkey applications.",
    icon: AI_Procut,
  },
  {
    title: "PLLM",
    initial: "PLLM (Private Large Language Model)",
    desc: "is an advanced natural language processing (NLP) system designed to use [AI]'s DataSyntax codes for masking sensitive enterprise data. Acting as an intermediary between third-party LLMs and enterprise data, PLLM ensures secure and efficient data processing.",
    icon: PLLM_Product,
  },
];
export default function Main() {
  const [activeProduct, setActiveProduct] = useState(init);
  const [animationMode, setAnimationMode] = useState(false);

  const handleSetActiveProduct = (title, i) => {
    setAnimationMode(true);
    if (title === activeProduct.title) setActiveProduct(init);
    else setActiveProduct(products[i]);
  };
  const stopAnimation = () => {
    setAnimationMode(false);
    setActiveProduct(init);
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence | Making Analytics and AI Accessible To
          All
        </title>
        <link rel="canonical" href="https://analyticsintelligence.com/" />
        <meta
          name="description"
          content="The growth of data in today's digital world is fuelling the growth of analytics across the enterprise and the development of artificial intelligence in various sectors. Our goal is to make analytics and artificial intelligence accessible to everyone.  We are a data analytics software development company with advanced
            solutions that empower businesses and enterprise customers with
            technologies to extract valuable insights from their data, driving
            informed decision-making and strategic growth."
        />
        <meta
          name="keywords"
          content="Snalytics Intelligence, data analytics, software development, leading companies, data-driven future, Analytics ready NLP systems, Large Language Models, Digital Analytics Stack, business questions, data-driven decision-making, data growth, information overload, middle layer analytics solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className="landing-bg2 pt-[-10px] text-white pb-[150px]  bg-[#03050B] overflow-x-hidden">
        <div className="container w-full flex justify-between items-center  pt-[126px] md:pt-[96px] py-[96px]">
          <div className=" flex flex-col flex-wrap gap-[20px] items-start">
            <Type />
            <div className="flex justify-center items-center gap-8">
              <Link to="/demo">
                <button className="py-3 px-6 flex justify-center items-center bg-poppingBlue text-white rounded-lg gap-2">
                  <span> Request a Demo</span> <ArrowForward />
                </button>
              </Link>
              <Link to="/company">
                <p>Learn More</p>
              </Link>
            </div>
          </div>
          <div className=" md:block hidden gif-radial-gradient">
            <img
              className="gif mt-[-9rem]"
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1685441769/cloner-cube-simple-copy_1_txcdi2_jx9jif.gif"
              alt="illustration"
            />
          </div>
        </div>
        <div className="container text-center lg:py-[96px] py-[60px] flex flex-col gap-4  ">
          <h2 className="text-[26px] max-w-[828px] mx-auto text-center text-poppingBlue md:text-[33px] font-[600] leading-[150%]">
            Transform <span className="mx-2 text-white">Data Analysis</span>{" "}
            with Enterprise [AI] and Intelligence as a Service
          </h2>
          <p className="w-full max-w-[1017px] mx-auto text-center text-[rgba(255,255,255,0.80)]">
            As data grows exponentially with digital transformation, enterprises
            struggle to make data-driven decisions due to a shortage of
            analytics skills. [AI] Analytics Intelligence offers innovative
            generative AI solutions that address these challenges.
          </p>
        </div>
        <div className="container flex flex-col  lg:py-[72px] ">
          <h1 className="text-[32px] text-poppingBlue md:text-[40px] font-bold leading-[150%]">
            Our Partners
          </h1>
          <Partners />
        </div>
        <div className="container pt-[20px] lg:py-[64px] py-[60px] flex flex-col gap-10 md:gap-[96px] 2">
          <h1 className="max-w-[728px] mx-auto text-center text-[32px] text-poppingBlue md:text-[40px] font-bold leading-[150%]">
            User Cases
          </h1>
          <div className="w-full max-w-[1296px] mx-auto flex flex-col md:flex-row justify-between items-center gap-6 pb-20">
            {useCases.map((c) => (
              <Link to={c.path} key={c.title}>
                <div className="w-full max-w-[392px] py-[30px] px-[19px] flex flex-col gap-[48px] card-style hover:shadow-2xl">
                  <div>
                    <h1 className="gradient-text text-[24px]">{c.title}</h1>
                    <p className="blurry-text text-sm">{c.desc}</p>
                  </div>
                  <img src={c.image} alt={c.title} />
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="container flex flex-col  py-[40px]  lg:py-[72px]">
          <h1 className="max-w-[728px] mx-auto text-center text-[32px] text-poppingBlue md:text-[40px] font-bold leading-[150%]">
            Our Clients
          </h1>
          <p className="text-center blurry-text">
            We are proud to have worked with leading companies worldwide, to
            help steer their journey towards a data-driven future.
          </p>
          <Clients />
        </div>
        <div className="container pt-[20px] lg:py-[64px] py-[60px] flex flex-col gap-10 md:gap-[90px] lg:gap-[0px] ">
          <h1 className="max-w-[728px] text-[32px] text-poppingBlue md:text-[40px] font-bold leading-[150%]">
            Products
          </h1>
          <div className="w-full mx-auto flex flex-col md:flex-row justify-between gap-2">
            <div className="w-full max-w-[1217] mx-auto flex flex-col lg:flex-row justify-between gap-2 lg:items-center">
              <div className="flex flex-col w-full max-w-[636px] justify-between gap-[48px]">
                <div className="flex border-b border-b-white justify-between gap-2 text-[20px] text-center md:text-[32px] font-[300]  cursor-pointer">
                  {products.map((p, i) => (
                    <h1
                      className={`pb-3 md:pb-4 ${
                        activeProduct.title === p.title
                          ? "border-b-4 border-b-poppingBlue scale-[1.01]"
                          : "text-white scale-[0.9]"
                      } transition-all duration-500 font-[600]`}
                      onClick={() => {
                        activeProduct.title === p.title
                          ? stopAnimation()
                          : handleSetActiveProduct(p.title, i);
                      }}
                      key={p.title}
                    >
                      {p.title}
                    </h1>
                  ))}
                </div>
                <div>
                  <p
                    className="w-full text-[19px] blurry-text text-justify"
                    onClick={stopAnimation}
                  >
                    {animationMode ? (
                      activeProduct.title === products[0].title ? (
                        <>
                          <span className="text-poppingBlue mr-2">
                            {products[0].title}
                          </span>{" "}
                          {products[0].desc}
                        </>
                      ) : activeProduct.title === products[1].title ? (
                        <>
                          <span className="text-poppingBlue mr-2">
                            {products[1].title}
                          </span>{" "}
                          {products[1].desc}
                        </>
                      ) : activeProduct.title === products[2].title ? (
                        <>
                          <span className="text-poppingBlue mr-2">
                            {products[2].initial}
                          </span>{" "}
                          {products[2].desc}
                        </>
                      ) : (
                        "Our comprehensive product suite offers innovative solutions for enterprises seeking to capitalise on generative AI for data analytics, from an analytics RAG platform that integrates various data sources to deliver focused Assistant/Agent applications. A conversational AI agent that acts as your dedicated data analyst."
                      )
                    ) : (
                      "Our comprehensive product suite offers innovative solutions for enterprises seeking to capitalise on generative AI for data analytics, from an analytics RAG platform that integrates various data sources to deliver focused Assistant/Agent applications. A conversational AI agent that acts as your dedicated data analyst."
                    )}
                  </p>
                  <p
                    className="w-full mt-10 text-justify"
                    onClick={stopAnimation}
                  >
                    {animationMode ? (
                      activeProduct.title === products[0].title ? (
                        <Link
                          className=" text-white hover:text-poppingBlue"
                          to="/data-assistant"
                        >
                          Learn More <NorthEast />
                        </Link>
                      ) : activeProduct.title === products[1].title ? (
                        <Link
                          className=" text-white hover:text-poppingBlue"
                          to="/ai-platform"
                        >
                          Learn More <NorthEast />
                        </Link>
                      ) : activeProduct.title === products[2].title ? (
                        <Link
                          className=" text-white hover:text-poppingBlue"
                          to="/pllm"
                        >
                          Learn More <NorthEast />
                        </Link>
                      ) : null
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-10 md:gap-0 justify-center items-center w-full max-w-[502px] mt-4 lg:mt-10">
                <img
                  src={DA_Product}
                  alt="data assistant"
                  className={`-mr-44 -ml-20 md:-ml-0 z-[3] ${
                    animationMode
                      ? activeProduct.title === products[0].title
                        ? "opacity-[1]"
                        : "opacity-30"
                      : "öpacity-[1]"
                  } transition-all duration-500 w-[200px] h-[270px] sm:w-[358px] sm:h-[362px]`}
                  onClick={() => {
                    activeProduct.title === products[0].title
                      ? stopAnimation()
                      : handleSetActiveProduct(products[0].title, 0);
                  }}
                />
                <img
                  src={AI_Procut}
                  alt="AI "
                  className={`-mt-60 z-[2] ${
                    animationMode
                      ? activeProduct.title === products[1].title
                        ? "opacity-[1]"
                        : "opacity-30"
                      : "opacity-[1]"
                  } transition-all duration-500 w-[200px] h-[250px] sm:w-[322px] sm:h-[327px]`}
                  onClick={() => {
                    activeProduct.title === products[1].title
                      ? stopAnimation()
                      : handleSetActiveProduct(products[1].title, 1);
                  }}
                />
                <img
                  src={PLLM_Product}
                  alt="pllm"
                  className={`-mt-48 -ml-20 md:-ml-44 z-[1] ${
                    animationMode
                      ? activeProduct.title === products[2].title
                        ? "opacity-[1]"
                        : "opacity-30"
                      : "opacity-[1]"
                  } transition-all duration-500 w-[190px] h-[200px] sm:w-[270px] sm:h-[276px]`}
                  onClick={() => {
                    activeProduct.title === products[2].title
                      ? stopAnimation()
                      : handleSetActiveProduct(products[2].title, 2);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="my-4 md:my-20 ">
          <div className="container flex w-full flex-col gap-[50px] lg:gap-[120px] ">
            <div className="flex md:flex-row-reverse flex-col md:gap-[5%] gap-[30px] items-center justify-between info-gradient px-5 lg:px-[67px] py-5 lg:py-[56px]  ">
              <div className="w-full max-w-[578px] flex flex-col gap-3">
                <h2 className="md:text-[40px] text-[24px] font-[600] leading-[150%]">
                  Embrace Self-Service Analytics
                </h2>
                <p className="sm:text-[18px] text-[16px] text-justify leading-8 blurry-text">
                  Adopt a self-service approach with Data Assistant,
                  democratizing data access and analysis for improved agility
                  and efficiency in decision-making.
                </p>
              </div>
              <div className="">
                <img src={Embrace} alt="illustrarion" />
              </div>
            </div>
            <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between info-gradient-alt px-5 lg:px-[67px] py-5 lg:py-[37px]  ">
              <div className="w-full max-w-[500px] flex flex-col gap-3">
                <h2 className="w-full md:text-[40px] text-[24px]  font-[600] leading-[150%]">
                  Unleash the Power of Your Data
                </h2>
                <p className="sm:text-[18px] text-[16px] text-justify leading-8 blurry-text">
                  Transform your business with our data analytics and generative
                  AI technology, enabling effective decision-making and business
                  success.
                </p>
              </div>
              <div className="">
                <img src={Gain} alt="illustrarion" />
              </div>
            </div>
            <div className="flex md:flex-row-reverse flex-col md:gap-[5%] gap-[30px] items-center justify-between info-gradient px-5 lg:px-[67px] py-5 lg:py-[37px]  ">
              <div className="w-full max-w-[578px] flex flex-col gap-3">
                <h2 className="md:text-[40px] text-[24px]  font-[600] leading-[150%]">
                  Say Goodbye to Information Overload
                </h2>
                <p className="sm:text-[18px] text-[16px] text-justify leading-8 blurry-text">
                  Our [AI] Platform, an ERP-level information management system,
                  processes, analyses, and communicates valuable insights
                  effortlessly.
                </p>
              </div>
              <div className="">
                <img src={Data_Growth} alt="illustrarion" />
              </div>
            </div>
            <div className="flex md:flex-row flex-col md:gap-[10%] gap-[30px] items-center justify-between info-gradient-alt px-5 lg:px-[67px] py-5 lg:py-[52px] ">
              <div className="w-full max-w-[578px] flex flex-col gap-3">
                <h2 className="md:text-[40px] text-[24px]  font-[600] leading-[150%]">
                  Gain Actionable Insights Instantly
                </h2>
                <p className="sm:text-[18px] text-[16px] text-justify leading-8 blurry-text">
                  With our analytics and data AI assistant monitoring KPIs and
                  goals, receive reports and insights in minutes, not days.{" "}
                </p>
              </div>
              <div className="lg:mr-4">
                <img src={ACT_INS} alt="illustration" />
              </div>
            </div>
          </div>
        </div>
        <div className="container flex flex-col py-10 lg:py-[72px] gap-[48px]">
          <h1 className="max-w-[728px] mx-auto text-center text-[32px] text-poppingBlue md:text-[40px] font-bold leading-[150%]">
            At <span className="text-white">[AI]</span> We Offer
          </h1>
          <div className="flex flex-col md:gap-[186px] gap-[30px] items-center justify-between info-gradient-alt px-5 lg:px-[67px] py-5 lg:py-[102px]  ">
            <div className="flex justify-between items-center gap-8 flex-col md:flex-row w-full text-justify">
              <div className="flex flex-col gap-6 w-full max-w-[480px]">
                <div className="flex gap-3 items-start sm:items-center flex-col-reverse sm:flex-row">
                  <h1 className="text-[20px] md:text-[28px] font-[600]">
                    Innovative Analytics Solutions
                  </h1>
                  <img src={CEA} alt="icon" />
                </div>
                <p className="blurry-text">
                  Addressing the shortage of analytics skills, our platform
                  integrates advanced LLM and NLP technologies, empowering
                  businesses to derive marketing insights and optimise
                  processes, driving enhanced profitability.
                </p>
              </div>
              <div className="flex flex-col gap-6 w-full max-w-[440px]">
                <div className="flex gap-3 items-start sm:items-center flex-col-reverse sm:flex-row">
                  <h1 className="text-[20px] md:text-[28px] font-[600]">
                    Real-Time Insights
                  </h1>
                  <img src={RTI} alt="icon" />
                </div>
                <p className="blurry-text">
                  Overcome data-driven decision challenges with timely insights
                  provided by our conversational and generative AI technology,
                  transforming how enterprises make decisions.
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center gap-8 flex-col md:flex-row w-full text-justify">
              <div className="flex flex-col gap-6 w-full max-w-[480px]">
                <div className="flex gap-3 items-start sm:items-center flex-col-reverse sm:flex-row">
                  <h1 className="text-[20px] md:text-[28px] font-[600]">
                    Democratised Data Access
                  </h1>
                  <img src={DDA} alt="icon" />
                </div>
                <p className="blurry-text">
                  Our solutions open data access to all team members,
                  simplifying data analysis and fostering a data-driven culture
                  that supports informed decision-making across the
                  organisation.
                </p>
              </div>
              <div className="flex flex-col gap-6 w-full max-w-[440px]">
                <div className="flex gap-3 items-start sm:items-center flex-col-reverse sm:flex-row">
                  <h1 className="text-[20px] md:text-[28px] font-[600]">
                    Simplified Data Processing
                  </h1>
                  <img src={SDP} alt="icon" />
                </div>
                <p className="blurry-text">
                  Powered by large language models, our platform simplifies
                  complex data processing tasks, eliminating information
                  overload and providing clearer, actionable insights.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container flex flex-col gap-10 md:gap-[48px] py-14"
          id="faqs"
        >
          <h1 className="max-w-[728px] mx-auto text-center text-[32px] text-poppingBlue md:text-[40px] font-bold leading-[150%]">
            Frequently Asked Questions (FAQs)
          </h1>
          <Faq />
        </div>
        <div className="container text-center flex flex-col gap-2  lg:py-[96px] py-[60px]">
          <h1 className="gradient-header text-[28px] md:text-[40px] font-bold leading-[150%] tracking-[-0.4px]">
            Accelerate Your Path to Production AI
          </h1>
          <p className="px-2 w-full max-w-[900px] mx-auto blurry-text text-center font-manrope">
            [AI] offers turnkey solutions for implementing a generative AI
            strategy focused on efficiency through data analysis. Our platform
            enables the creation of generative AI agent applications,
            agent-based data analytics, and secure, privacy-focused NLP systems.
          </p>
          <Link to="demo">
            <button className="w-max mx-auto mt-4 flex justify-center items-center px-[30px] py-4 bg-poppingBlue rounded-xl">
              Request a Demo
            </button>
          </Link>
        </div>
      </main>
      <style jsx="true">
        {`
          .gif {
            object-fit: cover;
            height: 650px;
            width: 1004px;
          }

          .gif-radial-gradient {
            width: 520px;
            height: 520px;
            flex-shrink: 0;
            border-radius: 520px;
            margin-right: -60px;
            border: 79.715px solid rgba(0, 115, 255, 0.05);
          }
        `}
      </style>
    </>
  );
}
