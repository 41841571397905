import { Helmet, HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AI_Hero_BG, AL, CDSE, DSW, EKT, IAI, LangCon, MP } from "../../assets";
import { useState } from "react";
const helmetData = new HelmetData({});

const init = {
  title: "",
  desc: "",
  icon: "",
};
const products = [
  {
    title: "Application and Language Layer",
    desc: "This frontend conversational AI interface is powered by industry-leading foundational models, enabling users to interact seamlessly with their agent systems. Users can employ multiple agents with various skills, facilitating comprehensive engagement with analytics and enterprise data.",
    icon: LangCon,
  },
  {
    title: "Analytics and Algorithm Layer",
    desc: "Our platform effortlessly connects to your data storage/warehouse layer via APIs, ensuring seamless access to the information needed for advanced analytics and precise calculations.",
    icon: AL,
  },
  {
    title: "Data Warehouse and Compute Layer",
    initial: "Private Large Language Model (PLLM)",
    desc: "Utilise Data Language Model (DLM) and Private Large Language Model (PLLM) for natural language query interpretation.",
    icon: DSW,
  },
];
export default function AiPlatform() {
  const [activeProduct, setActiveProduct] = useState(init);
  const [animationMode, setAnimationMode] = useState(false);

  const handleSetActiveProduct = (title, i) => {
    setAnimationMode(true);
    if (title === activeProduct.title) setActiveProduct(init);
    else setActiveProduct(products[i]);
  };
  const stopAnimation = () => {
    setAnimationMode(false);
    setActiveProduct(init);
  };
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Analytics Intelligence | Products | AI Platform</title>
        <link rel="canonical" href="/" />
        <meta
          name="description"
          content="[AI] Platform is an innovative retrieval augmented generations (RAG) platform designed to develop data analytics, conversational AI Agents, and Assistant systems for diverse enterprise applications. It comprises three integrated layers: the Application and Language Layer, the Analytics and Algorithm Layer, and the Data Warehouse and Compute Layer."
        />
        <meta
          name="keywords"
          content="Analytics intelligence, Application and Language Layer, Analytics and Algorithm Layer, Data Warehouse and Compute Layer, leading companies, data-driven future, Analytics ready NLP systems, Large Language Models, Digital Analytics Stack, business questions, data-driven decision-making, data growth, information overload, middle layer analytics solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className="ai-bg  text-white pb-[150px]   bg-[#03050B]">
        <div className="container py-[100px]  lg:py-[150px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] justify-between ">
          <div className="w-full xl:px-3 mx-auto flex md:flex-row flex-col md:gap-[5%] gap-[50px] lg:items-center justify-between  ">
            <div className="w-full max-w-[600px] flex flex-col gap-[30px] md:gap-[36px]">
              <h3 className="md:text-[40px] text-[28px] font-[700] gradient-h1 leading-[150%]">
                [AI] Platform
              </h3>
              <p className="w-full text-base md:text-[19px] leading-[180%] font-[300]">
                <span className="font-bold">[AI] Platform</span> is an
                innovative retrieval augmented generations (RAG) platform
                designed to develop data analytics, conversational AI Agents,
                and Assistant systems for diverse enterprise applications. It
                comprises three integrated layers: the Application and Language
                Layer, the Analytics and Algorithm Layer, and the Data Warehouse
                and Compute Layer.
              </p>
              <div className="flex  items-center gap-8">
                <Link to="/demo">
                  <button className="py-3 px-6 flex justify-center items-center bg-poppingBlue text-white rounded-lg gap-2">
                    <span> Request a Demo</span>
                  </button>
                </Link>
                <a href="#more">
                  <p>Learn More</p>
                </a>
              </div>
            </div>
            <div className="">
              <img src={AI_Hero_BG} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div
          className="container flex flex-col py-[40px] lg:py-[72px] gap-[64px]"
          id="more"
        >
          <div>
            <h1 className="text-[20px] font-[600] leading-[150%]">
              STRUCTURES
            </h1>
            <div className="linear-line h-[1px] mt-[6px] w-full max-w-[570px]" />
          </div>
          <div className="flex flex-col md:flex-row md:gap-[5%] gap-[50px] justify-between lg:items-center">
            <div>
              <div className={`w-full max-w-[570px]`}>
                <h1
                  className={`cursor-pointer text-poppingBlue text-[26px] md:text-[33px] leading-[150%] mb-[6px] ${
                    animationMode
                      ? activeProduct.title === products[0].title
                        ? "font-[700]"
                        : "font-[400]"
                      : "font-[400]"
                  }`}
                  onClick={() => {
                    activeProduct.title === products[0].title
                      ? stopAnimation()
                      : handleSetActiveProduct(products[0].title, 0);
                  }}
                >
                  Application and Language Layer
                </h1>
                <p
                  className={`${
                    animationMode
                      ? activeProduct.title === products[0].title
                        ? "text-white"
                        : "blurry-text"
                      : "blurry-text"
                  }`}
                  onClick={stopAnimation}
                >
                  This frontend conversational AI interface is powered by
                  industry-leading foundational models, enabling users to
                  interact seamlessly with their agent systems. Users can employ
                  multiple agents with various skills, facilitating
                  comprehensive engagement with analytics and enterprise data.
                </p>
                <div className="linear-line h-[1px] mt-[46px] w-full" />
              </div>
              <div className={` w-full max-w-[570px] my-[46px]`}>
                <h1
                  className={`cursor-pointer text-poppingBlue text-[26px] md:text-[33px] leading-[150%] mb-[6px] ${
                    animationMode
                      ? activeProduct.title === products[1].title
                        ? "font-[700]"
                        : "font-[400]"
                      : "font-[400]"
                  }`}
                  onClick={() => {
                    activeProduct.title === products[1].title
                      ? stopAnimation()
                      : handleSetActiveProduct(products[1].title, 1);
                  }}
                >
                  Analytics and Algorithm Layer
                </h1>
                <p
                  className={`${
                    animationMode
                      ? activeProduct.title === products[1].title
                        ? "text-white"
                        : "blurry-text"
                      : "blurry-text"
                  }`}
                  onClick={stopAnimation}
                >
                  The core of [AI] Platform, this layer includes two pivotal
                  systems:
                  <ul className="list-disc ml-5">
                    <li>
                      Data Language Model: A specialised language model trained
                      for data analytics.
                    </li>
                    <li>
                      Analytics Engine: Runs a suite of data analytics
                      algorithms, working in tandem with the Data Warehouse and
                      Compute Layer
                    </li>
                  </ul>
                </p>
                <div className="linear-line h-[1px] mt-[46px] w-full -rotate-180" />
              </div>
              <div className={` w-full max-w-[570px]`}>
                <h1
                  className={`cursor-pointer text-poppingBlue text-[26px] md:text-[33px] leading-[150%] mb-[6px] ${
                    animationMode
                      ? activeProduct.title === products[2].title
                        ? "font-[700]"
                        : "font-[400]"
                      : "font-[400]"
                  }`}
                  onClick={() => {
                    activeProduct.title === products[2].title
                      ? stopAnimation()
                      : handleSetActiveProduct(products[2].title, 2);
                  }}
                >
                  Data Warehouse and Compute Layer
                </h1>
                <p
                  className={`${
                    animationMode
                      ? activeProduct.title === products[2].title
                        ? "text-white"
                        : "blurry-text"
                      : "blurry-text"
                  }`}
                  onClick={stopAnimation}
                >
                  Fast-track your AI deployment with our turnkey analytics
                  stack. Connect to on-premise, private, or public cloud data
                  storage providers to analyse enterprise data efficiently.
                  Access GPU compute resources through [AI] Platform's cloud
                  partners, leveraging GPU-accelerated infrastructure for
                  intensive generative AI workloads.
                </p>
              </div>
            </div>

            <div
              className={`flex flex-col justify-center items-center w-full max-w-[502px] mt-4 lg:mt-10 ${
                animationMode ? "gap-8" : "gap-0"
              } `}
            >
              <img
                src={products[0].icon}
                alt={products[0].title}
                className={`z-[3] -ml-40 sm:-ml-60 ${
                  animationMode
                    ? activeProduct.title === products[0].title
                      ? "opacity-[1]"
                      : "opacity-30"
                    : "öpacity-[1]"
                } transition-all duration-700 cursor-pointer`}
                onClick={() => {
                  activeProduct.title === products[0].title
                    ? stopAnimation()
                    : handleSetActiveProduct(products[0].title, 0);
                }}
              />
              <img
                src={products[1].icon}
                alt={products[1].title}
                className={`-mt-36 -ml-5 sm:-ml-10 z-[2] ${
                  animationMode
                    ? activeProduct.title === products[1].title
                      ? "opacity-[1]"
                      : "opacity-30"
                    : "opacity-[1]"
                } transition-all duration-700 cursor-pointer`}
                onClick={() => {
                  activeProduct.title === products[1].title
                    ? stopAnimation()
                    : handleSetActiveProduct(products[1].title, 1);
                }}
              />
              <img
                src={products[2].icon}
                alt={products[2].title}
                className={`-mt-36 ml-20 sm:ml-32 z-[1] ${
                  animationMode
                    ? activeProduct.title === products[2].title
                      ? "opacity-[1]"
                      : "opacity-30"
                    : "opacity-[1]"
                } transition-all duration-700 cursor-pointer`}
                onClick={() => {
                  activeProduct.title === products[2].title
                    ? stopAnimation()
                    : handleSetActiveProduct(products[2].title, 2);
                }}
              />
            </div>
          </div>
        </div>
        <div className="container flex flex-col py-[40px] lg:py-[72px] gap-[64px]">
          <h1 className="max-w-[728px] mx-auto text-center text-[32px] text-poppingBlue md:text-[40px] font-semibold leading-[150%]">
            Capabilities <span className="gradient-h1">of [AI]</span> Platform
          </h1>
          <div className="flex flex-col md:gap-[170px] gap-[30px] items-center justify-between info-gradient-alt px-5 lg:px-[67px] py-5 lg:py-[102px]  ">
            <div className="flex justify-between gap-4 flex-col md:flex-row w-full ">
              <div className="flex flex-col gap-6 w-full max-w-[530px]">
                <div className="flex gap-2 items-start sm:items-center flex-col-reverse sm:flex-row">
                  <h1 className="text-[20px] md:text-[28px] font-[600]">
                    Access to Enterprise Data Sources
                  </h1>
                  <img src={CDSE} alt="icon" />
                </div>
                <p className="blurry-text max-w-[440px]">
                  Create generative AI applications that access and analyse
                  various analytics systems and enterprise data from cloud
                  providers. Manage sales, marketing, supply chain, and other
                  business units from a unified source.
                </p>
              </div>
              <div className="flex flex-col gap-6 w-full max-w-[520px]">
                <div className="flex gap-2 items-start sm:items-center flex-col-reverse sm:flex-row">
                  <h1 className="text-[20px] md:text-[28px] font-[600]">
                    Real-Time KPI and Goal Monitoring
                  </h1>
                  <img src={EKT} alt="icon" />
                </div>
                <p className="blurry-text max-w-[440px]">
                  Go beyond static reports—create agents to monitor KPIs,
                  providing real-time updates and recommendations to optimise
                  objectives. Align strategic goals with the relevant users and
                  teams, with assistants communicating progress continuously.
                </p>
              </div>
            </div>
            <div className="flex justify-between gap-4 flex-col md:flex-row w-full ">
              <div className="flex flex-col gap-6 w-full max-w-[510px]">
                <div className="flex gap-3 md:gap-2 items-start sm:items-center flex-col-reverse sm:flex-row">
                  <h1 className="text-[20px] md:text-[28px] font-[600]">
                    Add Skills and Tools
                  </h1>
                  <img src={MP} alt="icon" />
                </div>
                <p className="blurry-text max-w-[440px]">
                  Enhance your assistant's capabilities with add-on tools and
                  skills from the marketplace, enabling them to perform specific
                  data-related tasks efficiently.
                </p>
              </div>
              <div className="flex flex-col gap-6 w-full max-w-[520px]">
                <div className="flex gap-2 items-start sm:items-center flex-col-reverse sm:flex-row">
                  <h1 className="text-[20px] md:text-[28px] font-[600]">
                    Intelligent Assistant Integration
                  </h1>
                  <img src={IAI} alt="icon" />
                </div>
                <p className="blurry-text max-w-[440px]">
                  Optimise business workflows with a data-driven generative AI
                  assistant application. Powered by a RAG system, it connects
                  with leading analytics solutions and integrates seamlessly
                  with various enterprise tools.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
