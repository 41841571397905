import { Helmet, HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { client } from "../../../client";
import Swiper, { Navigation, Pagination } from "swiper";
import { Dots } from "../../../assets";
const helmetData = new HelmetData({});

export default function Events() {
  const [events, setEvents] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState("");
  const [searchPage, setSearchPage] = useState(false);

  const sortedEvents = events?.sort((a, b) => b.id - a.id);
  useEffect(() => {
    new Swiper(".swiper", {
      modules: [Navigation, Pagination],
      // Optional parameters
      direction: "horizontal",
      loop: true,

      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  // useEffect(()=>{
  //   fetch(`https://cms.analyticsintelligence.ai/api/events`)
  //   .then(res => res.json())
  //   .then(event => {
  //     setEvents(event.data);
  //   })
  // },[])
  useEffect(() => {
    client
      .fetch(
        `*[_type == "Event"]{
        title,
        speakers,
        date,
        flyers,
        time,
        watch_link,
        twitter,
        id,
        is_global
      }`
      )
      .then((data) => {
        setEvents(data.filter((p) => p?.is_global));
      });
  }, []);
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Artificial Intelligence | Making Artificial and AI Accessible To
          All
        </title>
        <link rel="canonical" href="/" />
        <meta
          name="description"
          content="The growth of data in today's digital world is fuelling the growth of Artificial across the enterprise and the development of artificial intelligence in various sectors. Our goal is to make Artificial and artificial intelligence accessible to everyone"
        />
        <meta
          name="keywords"
          content="leading companies, data-driven future, Artificial ready NLP systems, Large Language Models, Digital Artificial Stack, business questions, data-driven decision-making, data growth, information overload, middle layer Artificial solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className=" events-bg text-white pb-[150px]   bg-[#03050B] ">
        <div className="container text-center py-[100px] lg:py-[150px] pb-[200px] flex flex-col gap-4 lg:gap-[36px] ">
          <h2 className="text-[28px] max-w-[728px] mx-auto text-center gradient-h1 md:text-[40px] leading-[150%] ">
            Events
          </h2>
          <p className="w-full max-w-[1076px] mx-auto text-center text-[rgba(255,255,255,0.80)]">
            At Analytics Intelligence, we host a range of virtual and physical
            events for organisations, brands and individuals interested in the
            exciting world of data and AI.
          </p>
        </div>
        {events.length > 0 && (
          <div className="container -mt-[100px]">
            <h1 className=" w-full max-w-[1176px] mx-auto  text-poppingBlue text-[28px] md:text-[33px] mb-6">
              Past Events
            </h1>
          </div>
        )}
        <div className="container z-[10]">
          <div className="w-full max-w-[1176px] mx-auto flex gap-[30px]  justify-between flex-wrap ">
            {sortedEvents &&
              sortedEvents.map((event) => (
                <div
                  className="lg:w-[31.5%] md:w-[48%] max-w-[400px] w-full mx-auto flex flex-col p-3 pb-5 rounded-lg border border-[rgba(0,115,255,0.50)] bg-[#0B1B3F] flex-shrink-0 justify-between"
                  key={event?.id}
                >
                  <div>
                    <img
                      src={event.flyers[0]}
                      alt="blog"
                      className="h-[200px] w-full rounded-lg"
                    />
                  </div>
                  <div className="w-full flex justify-between my-2 gap-1">
                    <p className="text-[16px] text-white leading-[150%] ">
                      {new Intl.DateTimeFormat("en-GB", {
                        dateStyle: "long",
                        weekday: undefined,
                      }).format(new Date(event.date))}
                    </p>
                    <p className="text-[16px] text-white leading-[150%] ">
                      {event.time}
                    </p>
                  </div>
                  <h1 className="text-white text-[23px] leading-[150%] mb-5">
                    {event.title}
                  </h1>
                  <div className="flex justify-between items-center">
                    <a
                      href={event.watch_link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white bg-poppingBlue px-[13px] py-[8px] rounded-[4px] text-[9px] font-[500] leading-[150%] w-max h-max"
                    >
                      {event.twitter ? "Listen In" : "Watch now"}
                    </a>
                    <div className="flex gap-[10px]">
                      {event.speakers.map((speaker) => (
                        <img
                          className="object-cover rounded-[50%] h-[44px] w-[44px] border-[3px] border-[#F3F3F3]"
                          src={speaker}
                          alt="avatar"
                          key={speaker}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>
    </>
  );
}
