import { Helmet, HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { client } from "../../../client";
const helmetData = new HelmetData({});

export default function Blogs() {
  const [posts, setPosts] = useState([]);
  const [news, setNews] = useState([]);
  const [featuredPost, setFeaturedPost] = useState([]);
  const [recentPosts, setRecentPosts] = useState({
    bool: true,
    name: "recentPosts",
  });

  const sortedPosts = posts
    .sort((a, b) => new Date(b.published_date) - new Date(a.published_date))
    .slice(0, 9);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "Title"]{
      title,
      author,
      body,
      author_avatar,
      cover_photo,
      tags,
      published_date,
      featured_post,
      id,
      is_global,
      news,
      external_link
    }`
      )
      .then((data) => {
        setPosts(data.filter((p) => p?.is_global).filter((n) => !n.news));
        setNews(
          data
            .filter((n) => n.news)
            .sort(
              (a, b) => new Date(b.published_date) - new Date(a.published_date)
            )
        );
        data.forEach((p) => {
          if (p.featured_post && p?.is_global) {
            setFeaturedPost(p);
          }
        });
      });
  }, []);

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Artificial Intelligence | Resources | Blog</title>
        <link rel="canonical" href="/blog" />
        <meta
          name="description"
          content="The future is here, and it's powered by AI. Our blog is dedicated to
            uncovering cutting-edge research and developments in artificial
            intelligence and data Artificial across a myriad of industries."
        />
        <meta
          name="keywords"
          content="Blog, leading companies, data-driven future, Artificial ready NLP systems, Large Language Models, Digital Artificial Stack, business questions, data-driven decision-making, data growth, information overload, middle layer Artificial solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className="  blogs-bg text-white pb-[150px]   bg-[#03050B]">
        <div className="container text-center py-[100px] lg:py-[150px] flex flex-col gap-4 lg:gap-[36px] ">
          <h2 className="text-[28px] font-[700] max-w-[728px] mx-auto text-center gradient-h1 md:text-[40px] leading-[150%]">
            Blog
          </h2>
          <p className="w-full max-w-[1076px] mx-auto text-center text-[rgba(255,255,255,0.80)]">
            The future is here, and it's powered by AI. Our blog is dedicated to
            uncovering cutting-edge research and developments in artificial
            intelligence and data Artificial across a myriad of industries.
          </p>
        </div>
        <div className="container ">
          {featuredPost.id && (
            <div className="flex md:flex-row flex-col md:gap-[20px] gap-[30px] items-center justify-between bg-[#0B1B3F] rounded-[24px] w-full max-w-[1176px] mx-auto p-6 md:p-[48px]">
              <div className="w-full max-w-[500px] flex flex-col items-start gap-3">
                <h3 className="w-full  md:text-[33px] text-[28px]  leading-[150%]">
                  {featuredPost.title}
                </h3>
                <p className="w-full font-[500] leading-[180%] ">
                  {featuredPost.body?.split(" ").slice(0, 30).join(" ") +
                    (featuredPost.body.split(" ").length > 10 ? "..." : "")}
                </p>
                <Link to={`/blog/${featuredPost.id}`}>
                  <button className="w-max mx-auto mt-4 flex justify-center items-center px-[30px] py-4 bg-poppingBlue rounded-xl">
                    Read More
                  </button>
                </Link>
              </div>
              <div>
                <img
                  src={featuredPost?.cover_photo}
                  alt="blog"
                  width={478}
                  height={336}
                  className="rounded-2xl"
                />
              </div>
            </div>
          )}
        </div>
        {(posts.length > 0 || news.length > 0) && (
          <div className="container my-[50px]">
            <div className="w-full max-w-[1176px] mx-auto flex gap-2 md:gap-6">
              <button
                className={`px-1 md:px-5 text-xs md:text-base py-[10px] flex justify-center items-center rounded-lg  ${
                  recentPosts.name === "recentPosts"
                    ? "bg-white text-black"
                    : "text-white bg-black"
                }`}
                onClick={() =>
                  setRecentPosts({
                    name: "recentPosts",
                    bool: true,
                  })
                }
              >
                Recent Posts
              </button>
              <button
                className={`px-1 md:px-5 text-xs md:text-base py-[10px] flex justify-center items-center rounded-lg  ${
                  recentPosts.name === "aiNews"
                    ? "bg-white text-black"
                    : "text-white bg-black"
                }`}
                onClick={() =>
                  setRecentPosts({
                    name: "aiNews",
                    bool: false,
                  })
                }
              >
                [AI] in the News
              </button>
              <button
                className={`px-1 md:px-5 text-xs md:text-base py-[10px] flex justify-center items-center rounded-lg  ${
                  recentPosts.name === "all"
                    ? "bg-white text-black"
                    : "text-white bg-black"
                }`}
                onClick={() =>
                  setRecentPosts({
                    name: "all",
                    bool: false,
                  })
                }
              >
                All Posts
              </button>
            </div>
          </div>
        )}
        <div className="container">
          <div className="w-full max-w-[1176px] mx-auto flex gap-[30px]  justify-between flex-wrap ">
            {recentPosts.name === "recentPosts"
              ? sortedPosts &&
                sortedPosts.map((post) => (
                  <div
                    className="lg:w-[31.5%] md:w-[48%] max-w-[400px] w-full mx-auto flex justify-between flex-col p-3 pb-5 rounded-lg border border-[rgba(0,115,255,0.50)] bg-[#0B1B3F] flex-shrink-0"
                    key={post?.id}
                  >
                    <div>
                      <img
                        src={post.cover_photo}
                        alt="blog"
                        className="object-cover h-[200px] w-full rounded-lg"
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-1">
                      <p className="bg-[#091020] text-white text-[8px] leading-[150%] p-1 rounded-sm w-max">
                        {post?.tags[0]}
                      </p>
                      <p className="text-[8px] text-[rgba(255,255,255,0.70)] leading-[150%] ml-1">
                        {new Intl.DateTimeFormat("en-GB", {
                          dateStyle: "long",
                          weekday: undefined,
                        }).format(new Date(post.published_date))}
                      </p>
                    </div>
                    <h1 className="text-white text-[23px] leading-[150%] mb-5">
                      {post.title}
                    </h1>
                    <Link
                      to={`/blog/${post.id}`}
                      className="text-white hover:border-b border-b-[#0073FF] text-[12px] font-[500] leading-[150%] w-max self-baseline justify-self-end"
                    >
                      Read Now
                    </Link>
                  </div>
                ))
              : recentPosts.name === "aiNews"
              ? news &&
                news.map((n) => (
                  <div
                    className="lg:w-[31.5%] md:w-[48%] max-w-[400px] w-full mx-auto flex justify-between flex-col p-3 pb-5 rounded-lg border border-[rgba(0,115,255,0.50)] bg-[#0B1B3F] flex-shrink-0"
                    key={n?.id}
                  >
                    <div>
                      <img
                        src={n.cover_photo}
                        alt="blog"
                        className="object-cover h-[200px] w-full rounded-lg"
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-1">
                      <p className="bg-[#091020] text-white text-[8px] leading-[150%] p-1 rounded-sm w-max">
                        {n?.tags[0]}
                      </p>
                      <p className="text-[8px] text-[rgba(255,255,255,0.70)] leading-[150%] ml-1">
                        {new Intl.DateTimeFormat("en-GB", {
                          dateStyle: "long",
                          weekday: undefined,
                        }).format(new Date(n.published_date))}
                      </p>
                    </div>
                    <h1 className="text-white text-[23px] leading-[150%] mb-5">
                      {n.title}
                    </h1>
                    <a
                      href={n.external_link}
                      target="_blank"
                      className="text-white hover:border-b border-b-[#0073FF] text-[12px] font-[500] leading-[150%] w-max self-baseline justify-self-end"
                      rel="noreferrer"
                    >
                      Read Now
                    </a>
                  </div>
                ))
              : posts &&
                posts.map((post) => (
                  <div
                    className="lg:w-[31.5%] md:w-[48%] max-w-[400px] w-full mx-auto flex justify-between flex-col p-3 pb-5 rounded-lg border border-[rgba(0,115,255,0.50)] bg-[#0B1B3F] flex-shrink-0"
                    key={post?.id}
                  >
                    <div>
                      <img
                        src={post.cover_photo}
                        alt="blog"
                        className="object-cover h-[200px] w-full rounded-lg"
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-1">
                      <p className="bg-[#091020] text-white text-[8px] leading-[150%] p-1 rounded-sm w-max">
                        {post?.tags[0]}
                      </p>
                      <p className="text-[8px] text-[rgba(255,255,255,0.70)] leading-[150%] ml-1">
                        {new Intl.DateTimeFormat("en-GB", {
                          dateStyle: "long",
                          weekday: undefined,
                        }).format(new Date(post.published_date))}
                      </p>
                    </div>
                    <h1 className="text-white text-[23px] leading-[150%] mb-5">
                      {post.title}
                    </h1>
                    <Link
                      to={`/blog/${post.id}`}
                      className="text-white hover:border-b border-b-[#0073FF] text-[12px] font-[500] leading-[150%] w-max self-baseline justify-self-end"
                    >
                      Read Now
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </main>
    </>
  );
}
