import { Helmet, HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import { ESI, GDM, MA, MarketingH } from "../../assets";
const helmetData = new HelmetData({});

export default function Marketing() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence | Solutions | Marketing & Advertising
        </title>
        <link rel="canonical" href="/marketing" />
        <meta
          name="description"
          content="From precise performance measurement to actionable insights, we empower you to engage your target audience effectively and drive exceptional traffic. Our solutions are meticulously crafted to optimise ROI, ensuring every marketing dollar spent produces desired results."
        />
        <meta
          name="keywords"
          content="Marketing & Advertising, Marketing Analytics, precise performance measurement, actionable insights, leading companies, data-driven future, Analytics ready NLP systems, Large Language Models, Digital Analytics Stack, business questions, data-driven decision-making, data growth, information overload, middle layer analytics solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className=" marketing-bg text-white pb-[150px]   bg-[#03050B]">
        <div className="container py-[100px]  lg:py-[150px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] justify-between xl:px-3">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="md:text-[40px] text-[28px] font-[700] gradient-h1 leading-[150%]">
                Marketing & Advertising
              </h3>
              <p className="w-full max-w-[545px] text-[16px] leading-[180%] blurry-text">
                From precise performance measurement to actionable insights, we
                empower you to engage your target audience effectively and drive
                exceptional traffic. Our solutions are meticulously crafted to
                optimise ROI, ensuring every marketing dollar spent produces
                desired results.
              </p>
            </div>
            <div className="">
              <img src={MarketingH} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container py-[20px] lg:py-[96px] flex flex-col gap-4 lg:gap-[36px] ">
          <h2 className="text-[28px] max-w-[728px] mx-auto sm:text-center gradient-h1 md:text-[33px] font-[700] leading-[150%]">
            Empower Your Marketing Team with [AI]
          </h2>
          <p className="w-full max-w-[1160px] mx-auto sm:text-center text-[rgba(255,255,255,0.80)]">
            Transform your marketing strategies with our cutting-edge AI
            solutions. Our platform equips your team with powerful tools to
            enhance campaign performance, optimise targeting, and unlock
            invaluable insights. From precision targeting to impactful creative
            insights, we empower your campaigns to reach the right audience at
            the right time, driving maximum engagement and ROI.
          </p>
        </div>
        <div className="container py-[40px]  lg:py-[76px]  ">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="w-full max-w-[464px] md:text-[33px] text-[28px] text-poppingBlue font-[600] leading-[150%]">
                Get a Dedicated Marketing Analytics Team Powered by AI
              </h3>
              <p className="w-full max-w-[504px] md:text-[18px] leading-[180%] blurry-text">
                With your Data Assistant constantly monitoring your campaign
                performance, quickly identify underperforming campaigns and
                focus on making informed decisions.
              </p>
            </div>
            <div className="">
              <img src={GDM} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container py-[40px]  lg:py-[76px] ">
          <div className="flex md:flex-row-reverse flex-col md:gap-[5%] gap-[30px] items-center justify-between  ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="w-full max-w-[464px] md:text-[33px] text-[28px] text-poppingBlue font-[600] leading-[150%]">
                Monitoring and Alerts
              </h3>
              <p className="w-full max-w-[504px] md:text-[18px] leading-[180%] blurry-text">
                Automate the monitoring of your marketing and advertising goal
                performance by tracking crucial business KPIs. Receive alerts to
                notify you when and why changes happen.
              </p>
            </div>
            <div className="">
              <img src={MA} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container py-[40px]  lg:py-[76px]  ">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="w-full max-w-[464px] md:text-[33px] text-[28px] text-poppingBlue font-[600] leading-[150%]">
                Easily Share Insights Across the Entire Organisation
              </h3>
              <p className="w-full max-w-[504px] md:text-[18px] leading-[180%] blurry-text">
                Insights identified by your [AI] Data Assistant can be shared
                with individual colleagues or the entire team, ensuring everyone
                is aligned and focused on the same insights.
              </p>
            </div>
            <div className="">
              <img src={ESI} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="company-bg-bottom ">
          <div className="container text-center flex flex-col gap-2  py-[40px] lg:py-[96px] ">
            <h1 className="gradient-header-alt md:text-[40px] font-bold leading-[150%] text-[24px] tracking-[-0.4px]">
              Start Your Data-Driven Journey with [AI]
            </h1>
            <p className="px-2 w-full max-w-[900px] mx-auto blurry-text text-center font-manrope">
              Gain access to data-driven insights, automate processes, and
              deliver personalised solutions to your customers.
            </p>
            <Link to="/demo">
              <button className="w-max mx-auto mt-4 flex justify-center items-center px-[30px] py-4 bg-poppingBlue rounded-xl">
                Request a Demo
              </button>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
