export default function SendIcon() {
  return (
    // <svg
    //   width="16"
    //   height="16"
    //   viewBox="0 0 16 16"
    //   fill="#fff"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fill-rule="evenodd"
    //     clip-rule="evenodd"
    //     d="M1.44731 0.105619C1.07815 -0.0789902 0.6335 -0.0178267 0.327895 0.259598C0.0222903 0.537023 -0.0814707 0.973704 0.0666757 1.35895L2.61935 7.99696L0.0668605 14.6243C-0.0814777 15.0095 0.0220426 15.4462 0.327477 15.7238C0.632912 16.0013 1.07751 16.0628 1.44676 15.8784L15.439 8.89191C15.7779 8.72266 15.9921 8.3764 15.9922 7.99754C15.9924 7.61868 15.7784 7.27229 15.4395 7.10284L1.44731 0.105619ZM4.37769 6.99724L2.85886 3.04764L10.7568 6.99724H4.37769ZM4.37731 8.99724L2.85977 12.9374L10.751 8.99724H4.37731Z"
    //   ></path>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.69199 11.897L9.10199 12.367C10.034 12.677 10.499 12.833 10.833 13.167C11.167 13.501 11.323 13.967 11.633 14.897L12.103 16.307C12.887 18.661 13.279 19.837 14 19.837C14.72 19.837 15.113 18.661 15.897 16.307L18.735 7.79502C19.287 6.13902 19.563 5.31102 19.126 4.87402C18.689 4.43702 17.861 4.71302 16.206 5.26402L7.69099 8.10402C5.33999 8.88702 4.16199 9.27902 4.16199 10C4.16199 10.721 5.33899 11.113 7.69199 11.897Z"
        fill="#0073FF"
        fill-opacity="0.25"
      />
      <path
        d="M7.69199 11.897L9.10199 12.367C10.034 12.677 10.499 12.833 10.833 13.167C11.167 13.501 11.323 13.967 11.633 14.897L12.103 16.307C12.887 18.661 13.279 19.837 14 19.837C14.72 19.837 15.113 18.661 15.897 16.307L18.735 7.79502C19.287 6.13902 19.563 5.31102 19.126 4.87402C18.689 4.43702 17.861 4.71302 16.206 5.26402L7.69099 8.10402C5.33999 8.88702 4.16199 9.27902 4.16199 10C4.16199 10.721 5.33899 11.113 7.69199 11.897Z"
        fill="#0073FF"
        fill-opacity="0.25"
      />
      <path
        d="M9.52602 13.842L7.46402 13.155C7.31973 13.1069 7.16637 13.0924 7.01561 13.1125C6.86485 13.1326 6.72066 13.1868 6.59402 13.271L5.50402 13.997C5.37499 14.083 5.27372 14.2046 5.21253 14.3471C5.15133 14.4895 5.13286 14.6467 5.15934 14.7994C5.18583 14.9522 5.25613 15.094 5.36171 15.2075C5.46728 15.3211 5.60358 15.4015 5.75402 15.439L7.70902 15.927C7.79699 15.949 7.87734 15.9945 7.94146 16.0586C8.00558 16.1227 8.05105 16.203 8.07302 16.291L8.56102 18.246C8.59853 18.3965 8.67895 18.5328 8.7925 18.6383C8.90605 18.7439 9.04782 18.8142 9.20059 18.8407C9.35336 18.8672 9.51053 18.8487 9.65299 18.7875C9.79544 18.7263 9.91705 18.6251 10.003 18.496L10.729 17.406C10.8132 17.2794 10.8675 17.1352 10.8876 16.9844C10.9077 16.8337 10.8931 16.6803 10.845 16.536L10.158 14.474C10.1089 14.3269 10.0262 14.1932 9.91656 14.0835C9.80687 13.9738 9.67316 13.8911 9.52602 13.842Z"
        fill="#0073FF"
      />
    </svg>
  );
}
