import { useForm } from "@formspree/react";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { toast } from "react-hot-toast";

export default function ConsultationForm() {
  const [isCompany, setIsCompany] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [state, handleSubmit, reset] = useForm("xeojojno");

  const handleSuccess = (message) => {
    toast.dismiss();
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  if (state.succeeded) {
    handleSuccess("Request sucessfully sent, We wil be in touch shortly");
    reset();
  }
  const handleChange = (newValue) => {
    setPhoneNumber(newValue);
  };

  return (
    <div
      className="container py-[28px] md:py-[64px] flex flex-col gap-6 partners-lines relative"
      id="consult-us"
    >
      <h1 className="max-w-[728px] mx-auto text-center text-[32px] gradient-header md:text-[40px] font-semibold leading-[150%]">
        Contact Us
      </h1>
      <form
        method="POST"
        onSubmit={handleSubmit}
        className="w-full max-w-[600px] mx-auto"
      >
        <div>
          <label className="text-poppingBlue text-[23px]" htmlFor="fullName">
            Full Name
          </label>
          <input
            required
            type="text"
            id="fullName"
            name="Full Name"
            placeholder="Enter full name"
            className="form-input bg-transparent"
          />
        </div>
        <div>
          <label className="text-poppingBlue text-[23px]" htmlFor="email">
            Email
          </label>
          <input
            required
            type="email"
            id="email"
            name="Email"
            placeholder="Enter email"
            className="form-input bg-transparent"
          />
        </div>
        <div className="text-white my-3">
          <label className="text-poppingBlue text-[23px]" htmlFor="phone">
            Phone Number
          </label>
          <MuiTelInput
            value={phoneNumber}
            onChange={handleChange}
            required
            type="tel"
            id="phone"
            name="Phone Number"
            placeholder="Enter phone number"
            className="unset w-full"
          />
        </div>
        {isCompany && (
          <>
            <div>
              <label
                className="text-poppingBlue text-[23px]"
                htmlFor="companyName"
              >
                Company Name
              </label>
              <input
                type="text"
                id="companyName"
                name="Company Name"
                placeholder="Enter company name"
                className="form-input bg-transparent"
              />
            </div>
            <div>
              <label
                className="text-poppingBlue text-[23px]"
                htmlFor="positionTitle"
              >
                Position Title
              </label>
              <input
                type="text"
                id="positionTitle"
                name="Position Title"
                placeholder="Enter position title"
                className="form-input bg-transparent"
              />
            </div>
          </>
        )}

        <div className="flex items-center gap-2 my-5">
          <input
            type="checkbox"
            id="isCompany"
            name="Company"
            checked={isCompany}
            onChange={() => setIsCompany(!isCompany)}
          />
          <label className="blurry-text text-[13px]" htmlFor="isCompany">
            Company
          </label>
        </div>
        <div className="flex flex-col sm:flex-row gap-3 w-max mx-auto">
          <button
            type="reset"
            className="w-max mx-auto mt-4 flex justify-center items-center px-[56px] py-4 text-white bg-dark rounded-xl"
          >
            Clear All
          </button>
          <button
            disabled={state.submitting}
            type="submit"
            role="form"
            className="w-max mx-auto mt-4 flex justify-center items-center px-[56px] py-4 bg-poppingBlue rounded-xl"
          >
            {state.submitting ? "Submitting" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
