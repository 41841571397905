import ReactMarkdown from 'react-markdown';


export default function JobDetailsModal({ data, close }) {
  return (
    <div className="absolute z-50 bg-[#fff] w-[90%] md:px-[65px] px-[20px] mx-auto top-[150px] py-[30px]">
      <div className="flex md:flex-row flex-col justify-between md:items-center gap-[20px] md:gap-0">
        <h1 className="text-[#20364D] font-[900] md:text-[30px] text-[25px]">
          {data[0].attributes.title}
        </h1>
        <div className="flex gap-[30px]">
          <button className="bg-[#0073FF] py-[10px] px-[26px] rounded-[10px] text-white font-[500] hover:opacity-70">
            <a
              href="https://form.jotform.com/230804076690052"
              target="_blank"
              rel="noreferrer"
            >
              Apply Now
            </a>
          </button>
          <button
            className="py-[10px] px-[26px] border border-[1px] border-[#0073FF] rounded-[12px] hover:bg-[#0073FF] hover:text-white text-black transition"
            onClick={() => close()}
          >
            Close
          </button>
        </div>
      </div>
      <div className="mt-[30px] flex flex-col gap-[26px]">
        <div className="flex gap-[40px] items-center">
          <div className="flex gap-[20px] items-center">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678175318/Vector_15_gp5d9l.svg"
              alt="briefcase-icon"
            />
            <span className="whitespace-nowrap">Team</span>
          </div>
          <span className="text-[#5C71A9] rounded-[10px] bg-[#F3F3F3] py-[2.5px] px-[15px]">
            {data[0].attributes.team}
          </span>
        </div>
        <div className="flex gap-[40px] items-center">
          <div className="flex gap-[20px] items-center">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678175327/Vector_16_uo268c.svg"
              alt="location-icon"
            />
            <span className="whitespace-nowrap">Location</span>
          </div>
          <span className="text-[#5C71A9] rounded-[10px] bg-[#F3F3F3] py-[2.5px] px-[15px]">
            {data[0].attributes.location}
          </span>
        </div>
      </div>
      <div className="text-[#231F20] mt-[32px]">
        <h2 className="md:text-[25px] text-[22px] font-[700] mb-[15px]">
          Job Description
        </h2>
        <div className='body pl-[20px]'>
          <ReactMarkdown children={data[0].attributes.job_description} />
        </div>
      </div>
      <div className="text-[#231F20] my-[32px]">
        <h2 className="md:text-[25px] text-[22px] font-[700] mb-[15px]">
          Job Qualification and Skills
        </h2>
        <div className='body pl-[20px]'>
          <ReactMarkdown children={data[0].attributes.qualification} />
        </div>
      </div>
      <a
        href="https://form.jotform.com/230804076690052"
        className="bg-[#0073FF] py-[10px] px-[26px] rounded-[10px] text-white font-[500] hover:opacity-70 mt-[27px]"
        target="_blank"
        rel="noreferrer"
      >
        Apply Now
      </a>
      <style jsx='true'>
        {
          `
          .body ul{
            display:flex;
            flex-direction:column;
            gap:5px;
            list-style-type: square;
          }
          `
        }
      </style>
    </div>
  );
}
