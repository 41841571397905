import { C1, C2, C3, C4, P1, P2, P3, P4, P5, P6, P7 } from "../../assets";

export default function Partners() {
  return (
    <div className="sliders">
      <div className="second-slider">
        <div className="images-host">
          <div className="images">
            <div className="scroll-img">
              <img src={P1} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P2} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P3} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P4} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P5} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P6} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P7} alt="Company logo" />
            </div>
          </div>
          <div className="images">
            <div className="scroll-img">
              <img src={P1} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P2} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P3} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P4} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P5} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P6} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P7} alt="Company logo" />
            </div>
          </div>
          <div className="images">
            <div className="scroll-img">
              <img src={P1} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P2} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P3} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P4} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P5} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P6} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P7} alt="Company logo" />
            </div>
          </div>
          <div className="images">
            <div className="scroll-img">
              <img src={P1} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P2} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P3} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P4} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P5} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P6} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={P7} alt="Company logo" />
            </div>
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .worldwide-flex {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }
        .worldwide-flex .join {
          flex-basis: 500px;
          margin-bottom: 2rem;
        }
        .worldwide-flex .join h3 {
          line-height: 1.5;
          font-size: 1.5rem;
        }
        .join a {
          text-decoration: none;
          color: inherit;
        }
        .join .btn-white {
          padding-top: 1rem;
          padding-bottom: 1rem;
          font-size: 1.3rem;
        }
        .worldwide-flex .img {
          flex-basis: 500px;
        }
        .started {
          text-decoration: none;
          color: inherit;
          display: flex;
          justify-content: center;
          margin: 1rem auto;
        }
        .started .btn-white {
          padding: 1rem 4rem;
          font-size: 1.2rem;
        }
        .container h3 {
          margin-bottom: 1rem;
        }
        .worldwide {
          background-color: #1f3569;
          padding-top: 5rem;
          padding-bottom: 5rem;
        }
        .second-slider {
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        .images-host {
          display: flex;
          flex-direction: row;
          overflow: hidden;
        }
        .sliders .images {
          display: grid;
          grid-template-columns: repeat(auto-fit, 170px);
          grid-gap: 2rem;
          grid-auto-rows: minmax(170px, auto);
          padding-top: 3rem;
          padding-bottom: 3rem;
          justify-content: center;
        }

        .scroll .scroll-img {
          background-color: #fff;
          color: #1f3569;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          padding: 1rem;
        }
        .scroll .scroll-img p {
          font-weight: 600;
          font-size: 0.8rem;
          margin-top: 1rem;
        }
        .news {
          color: #1f3569;
        }

        @keyframes marquee {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(-100%, 0);
          }
        }

        .second-slider .images {
          display: flex;
          justify-content: space-between;
          padding-left: 1rem;
          padding-right: 1rem;
          flex-direction: row;
          animation: marquee 50s linear infinite;
          padding-bottom: 1rem;
          padding-top: 1rem;
        }

        .second-slider .scroll-img {
          cursor: pointer;
          color: #1f3569;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 1rem;
        }
        .second-slider .scroll-img img {
          min-width: 120px;
          object-fit: cover;
        }
        .second-slider .scroll-img img.royal {
          min-width: 150px;
        }
      `}</style>
    </div>
  );
}

export function Clients() {
  return (
    <div className="sliders">
      <div className="second-slider">
        <div className="images-host">
          <div className="images">
            <div className="scroll-img">
              <img src={C1} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C2} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C3} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C4} alt="Company logo" />
            </div>
          </div>
          <div className="images">
            <div className="scroll-img">
              <img src={C1} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C2} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C3} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C4} alt="Company logo" />
            </div>
          </div>
          <div className="images">
            <div className="scroll-img">
              <img src={C1} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C2} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C3} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C4} alt="Company logo" />
            </div>
          </div>
          <div className="images">
            <div className="scroll-img">
              <img src={C1} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C2} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C3} alt="Company logo" />
            </div>
            <div className="scroll-img">
              <img src={C4} alt="Company logo" />
            </div>
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .worldwide-flex {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }
        .worldwide-flex .join {
          flex-basis: 500px;
          margin-bottom: 2rem;
        }
        .worldwide-flex .join h3 {
          line-height: 1.5;
          font-size: 1.5rem;
        }
        .join a {
          text-decoration: none;
          color: inherit;
        }
        .join .btn-white {
          padding-top: 1rem;
          padding-bottom: 1rem;
          font-size: 1.3rem;
        }
        .worldwide-flex .img {
          flex-basis: 500px;
        }
        .started {
          text-decoration: none;
          color: inherit;
          display: flex;
          justify-content: center;
          margin: 1rem auto;
        }
        .started .btn-white {
          padding: 1rem 4rem;
          font-size: 1.2rem;
        }
        .container h3 {
          margin-bottom: 1rem;
        }
        .worldwide {
          background-color: #1f3569;
          padding-top: 5rem;
          padding-bottom: 5rem;
        }
        .second-slider {
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        .images-host {
          display: flex;
          flex-direction: row;
          overflow: hidden;
        }
        .sliders .images {
          display: grid;
          grid-template-columns: repeat(auto-fit, 170px);
          grid-gap: 2rem;
          grid-auto-rows: minmax(170px, auto);
          padding-top: 3rem;
          padding-bottom: 3rem;
          justify-content: center;
        }

        .scroll .scroll-img {
          background-color: #fff;
          color: #1f3569;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          padding: 1rem;
        }
        .scroll .scroll-img p {
          font-weight: 600;
          font-size: 0.8rem;
          margin-top: 1rem;
        }
        .news {
          color: #1f3569;
        }

        @keyframes marquee {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(-100%, 0);
          }
        }

        .second-slider .images {
          display: flex;
          justify-content: space-between;
          padding-left: 1rem;
          padding-right: 1rem;
          flex-direction: row;
          animation: marquee 50s linear infinite;
          padding-bottom: 1rem;
          padding-top: 1rem;
        }

        .second-slider .scroll-img {
          cursor: pointer;
          color: #1f3569;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 1rem;
        }
        .second-slider .scroll-img img {
          min-width: 120px;
          object-fit: cover;
        }
        .second-slider .scroll-img img.royal {
          min-width: 150px;
        }
      `}</style>
    </div>
  );
}
