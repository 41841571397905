import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";


const rootElement = document.getElementById("root");
const AppContainer = (
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

if (rootElement.hasChildNodes()) {
  hydrate(AppContainer, rootElement);
} else {
  render(AppContainer, rootElement);
}
