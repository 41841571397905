import { Helmet, HelmetData } from "react-helmet-async";
import { Link } from "react-router-dom";
import { ATRS, RTNC, TQIA, UDSC } from "../../assets";

const helmetData = new HelmetData({});

export default function Retail() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence | Solutions | Retail and E-Commerce
        </title>
        <link rel="canonical" href="/retail" />
        <meta
          name="description"
          content=" Harness the power of cutting-edge AI technology to analyse vast troves of data, revealing valuable patterns and trends that shape your customers' preferences and needs. From browsing habits to purchase history, gain a comprehensive understanding of what drives your audience."
        />
        <meta
          name="keywords"
          content="Retail and E-Commerce, Unlock Domain-Specific insights, Actionable Insights, Targeted Retail Solutions, leading companies, data-driven future, Analytics ready NLP systems, Large Language Models, Digital Analytics Stack, business questions, data-driven decision-making, data growth, information overload, middle layer analytics solutions, process, analyse, communicate insight"
        />
      </Helmet>
      <main className=" retail-bg text-white pb-[150px]   bg-[#03050B]">
        <div className="container py-[100px]  lg:py-[150px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] justify-between ">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between xl:px-3 ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="md:text-[40px] text-[28px] font-[700] gradient-h1 leading-[150%]">
                Retail and E-Commerce
              </h3>
              <p className="w-full max-w-[545px] text-[16px] leading-[180%] blurry-text">
                Harness the power of cutting-edge AI technology to analyse vast
                troves of data, revealing valuable patterns and trends that
                shape your customers' preferences and needs. From browsing
                habits to purchase history, gain a comprehensive understanding
                of what drives your audience.
              </p>
            </div>
            <div className="">
              <img src={RTNC} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container  py-[20px] lg:py-[96px] flex flex-col gap-4 lg:gap-[36px] ">
          <h2 className="text-[28px] max-w-[728px] mx-auto sm:text-center gradient-h1 md:text-[33px] font-[700] leading-[150%]">
            Enter the Future of Retail, Powered by [AI]
          </h2>
          <p className="w-full max-w-[1180px] mx-auto sm:text-center text-[rgba(255,255,255,0.80)]">
            Our platform empowers you to optimise your marketing efforts with
            laser precision. Identify the most effective channels driving
            traffic to your website, refine your targeting strategies, and
            maximise your ROI. With real-time analytics at your fingertips, make
            informed decisions that drive tangible results. [AI] solutions are
            designed to adapt and evolve alongside your business.
          </p>
        </div>
        <div className="container py-[40px]  lg:py-[76px] ">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="w-full max-w-[464px] md:text-[33px] text-[28px] font-[600] text-poppingBlue leading-[150%]">
                Unlock Domain-Specific Insights
              </h3>
              <p className="w-full max-w-[504px] md:text-[18px] leading-[180%] blurry-text">
                Generate data-driven insights and recommendations with a private
                large language model that understands and generates
                domain-specific content.
              </p>
            </div>
            <div className="">
              <img src={UDSC} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container py-[40px]  lg:py-[76px]  ">
          <div className="flex md:flex-row-reverse flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full lg:max-w-[515px] flex flex-col gap-3">
              <h3 className="w-full lg:max-w-[464px] md:text-[33px] text-[28px] font-[600] text-poppingBlue leading-[150%]">
                Transform Queries into Actionable Insights
              </h3>
              <p className="w-full max-w-[504px] md:text-[18px] leading-[180%] blurry-text">
                Interact with your data using natural language, utilizing a data
                language model that interprets and transforms your retail
                queries into actionable commands.
              </p>
            </div>
            <div className="">
              <img src={TQIA} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="container py-[40px]  lg:py-[76px]   ">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="w-full max-w-[464px] md:text-[33px] text-[28px] font-[600] text-poppingBlue leading-[150%]">
                Access Targeted Retail Solutions
              </h3>
              <p className="w-full max-w-[504px] md:text-[18px] leading-[180%] blurry-text">
                Segment your customers based on various criteria, such as
                demographics, behavior, preferences, and loyalty. Provide
                customised and tailored offers and services with our solution.
              </p>
            </div>
            <div className="">
              <img src={ATRS} alt="illustrarion" />
            </div>
          </div>
        </div>
        <div className="company-bg-bottom ">
          <div className="container text-center flex flex-col gap-2  py-[40px] lg:py-[96px] ">
            <h1 className="gradient-header-alt md:text-[40px] font-bold leading-[150%] text-[24px] tracking-[-0.4px]">
              Start Your Data-Driven Journey with [AI]
            </h1>
            <p className="px-2 w-full max-w-[900px] mx-auto blurry-text text-center font-manrope">
              Gain access to data-driven insights, automate processes, and
              deliver personalised solutions to your customers.
            </p>
            <Link to="/demo">
              <button className="w-max mx-auto mt-4 flex justify-center items-center px-[30px] py-4 bg-poppingBlue rounded-xl">
                Request a Demo
              </button>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
