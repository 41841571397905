import {
  AAH,
  DAT,
  EDA,
  Fintech,
  GAE,
  IR,
  Marketing,
  PED,
  Retail,
  UUB,
} from "../../assets";
export const useCases = [
  {
    title: "Retail & E-Commerce",
    desc: "Utilise [AI] Platform to integrate generative AI features into your site, enhancing data analysis and customer interaction for optimised sales strategies.",
    image: Retail,
    path: "/retail",
  },
  {
    title: "Fintech & Finance",
    desc: "Leverage PLLM for secure generative AI applications, ensuring sensitive financial data is protected while automating expense management and reporting.",
    image: Fintech,
    path: "/fintech",
  },
  {
    title: "Marketing & Advertising",
    desc: "Employ Data Assistant for campaign management, enabling digital marketing teams to analyse performance and optimise targeting in real-time.",
    image: Marketing,
    path: "/marketing",
  },
];

export const consultancy = [
  {
    title: "Understand User Behaviors",
    desc: "We provide thorough digital and web data analysis to enhance understanding of user behaviours, website performance, and digital campaign effectiveness.",
    icon: UUB,
  },
  {
    title: "Improve ROI",
    desc: "We provide insights into marketing performance by analyzing data from various channels, hence improving ROI and campaign effectiveness.",
    icon: IR,
  },
  {
    title: "Ensure Data Accuracy",
    desc: "We provide solutions to efficiently organise, store, and manage data, ensuring its accuracy and accessibility.",
    icon: EDA,
  },
];

export const services = [
  {
    title: "Insight and Performance Reporting",
    desc: "We specialise in converting data into actionable insights to help you achieve your business objectives.",
  },
  {
    title: "Implementing Tracking and Configuring",
    desc: "Our dedicated team focuses on the implementation and configuration of digital analytics tracking and data layers.",
  },
  {
    title: "AI-Ready Data Implementation",
    desc: "We believe the future of analytics and insight reporting lies with AI agents and assistants. We work with clients to get their data AI-ready.",
  },
];

export const testimonials = [
  {
    title: "Digital Analytics Manager @ E-Commerce Company",
    body: "The Data Assistant has been a game-changer for us in how we manage digital analytics requests from our Digital Marketing teams. The speed and accuracy of the AI in responding to marketing analytics queries has allowed the Data and Analytics teams to focus on more deep-dive, qualitative analysis tasks.",
  },
  {
    title: "Conversion Rate Optimisation Specialist @ Insurance Company",
    body: "The Data Assistant has saved us countless hours of manual data analysis and provided us with actionable insights in real-time. This has allowed us to quickly optimise our campaigns and improve our conversion rates.",
  },
  {
    title: "Marketing Coordinator/Specialist @ Parcel Delivery Company",
    body: `"I'm able to track KPIs in real-time, allowing me to make data-driven decisions on the fly. The AI has been a game-changer for my marketing efforts."`,
  },

  {
    title: "Campaign Manager @ Digital Advertising Agency",
    body: "With the Data Assistant, I no longer have to spend hours pouring over spreadsheets. The AI gives me quick and accurate insights into my campaign's performance, freeing up my time for more creative tasks.",
  },

  {
    title: "Senior Marketing Manager @ Global Car Brand ",
    body: "As a Marketer I've always struggled with data analysis from tools like Adobe Analytics, but the Data Assistant makes it so easy! The AI provides me with quick answers to my questions, actionable insights, which has helped me improve my campaigns and achieve my goals.",
  },

  {
    title: "Director of Digital Marketing @ Lead Generation Company",
    body: "I was skeptical at first, but the Data Assistant has completely transformed my approach to marketing analytics. The AI has changed my Digital Marketing teams works, they are quicker at identifying trends and optimizing their campaigns for better results.",
  },
  {
    title: "Senior Marketing Manager @ Fintech Company",
    body: "The Data Assistant is like having my own personal analytics expert! The AI helps me understand my data in a way that makes sense and provides me with recommendations for improvement. It's made a huge difference in my digital marketing efforts.",
  },
];

export const trainings = [
  {
    title: "Digital Analytics Training",
    desc: "Discover the power of data-driven decision-making with our training masterclass, tailored for all skill levels. From foundational concepts to advanced techniques, we cover everything you need to succeed in the dynamic field of digital data analytics.",
    icon: DAT,
  },
  {
    title: "Adobe Analytics Mastery",
    desc: "Gain proficiency in Adobe Analytics with our specialised training masterclass. Learn to harness the value of Adobe Analytics custom variables to drive measurable business growth.",
    icon: AAH,
  },
  {
    title: "Google Analytics 4 Expertise",
    desc: "Become a GA4 pro with our in-depth training programs. Understand user behavior, track website performance, and extract actionable insights to enhance your online strategies.",
    icon: GAE,
  },
  {
    title: "Prompt Engineering for Data Analytics",
    desc: "As conversational AI becomes the new user interface, data analytics is being delivered by generative AI. Prompt engineering is the skill needed to get the best from these new generation analytics applications. Master the skills needed to interact with generative AI analytics applications like Data Assistant.",
    icon: PED,
  },
];
