import { Link } from "react-router-dom";
import { BG_Pop, Company, Company2, Dots, Sales } from "../../assets";
import Careers from "./careers/Careers";
import { Helmet, HelmetData } from "react-helmet-async";
import { toast } from "react-hot-toast";
import { useForm } from "@formspree/react";
import { useState } from "react";
const helmetData = new HelmetData({});
const aboutUs = [
  {
    title: "Making Analytics and Artificial Intelligence Accessible to All",
    desc: "We are a data analytics and artificial intelligence software development company, dedicated to harnessing the power of AI to drive transformative changes across various industries, including financial, telecommunications, and government organisations. Our product portfolio, including the [AI] Platform, Data Assistant, and PLLM, reflects our mission to make data securely accessible to all, catering to the unique needs of various sectors.",
  },
  {
    title: "Origins And Purpose",
    desc: "Our journey began in 2011 when our founder, a web analytics implementation specialist, working with in a leading publishing firm recognized the growing need for businesses to interpret digital data for informed decisions. He also noticed a shortage of skilled digital analysts in the market. To address this, we delved into machine learning and natural language processing system to harness AI for communicating data insights through conversation. By 2018, we launched our MVP, a data pipeline, and an intelligent middle-layer application that connects to multiple data sources, interprets, and communicates analysis through a conversational AI interface, empowering informed decision-making.",
  },
  {
    title: "Our Mission",
    desc: "Our mission is to democratize data insight and empower organisations of all sizes to become data-driven. We are committed to revolutionizing how businesses harness AI and data analytics. Through our innovative AI solutions, we strive to make complex data understandable and actionable for everyone, fostering a culture of collaboration, efficiency, and informed decision-making.",
  },
  {
    title: "Safety & Responsibility",
    desc: "At [AI] Analytics Intelligence, safety and responsibility are core values guiding our work in generative AI, data analytics, and artificial intelligence. We prioritize rigorous testing, transparency, user privacy, and ongoing dialogue with experts and stakeholders, which is one of the reasons we developed our PLLM product.\n               Our commitment extends to proactive measures for risk mitigation, comprehensive team training, and advocacy for ethical standards and regulations in AI. We strive to foster trust, empower users, and contribute positively to the broader AI safety research agenda, all with the aim of creating a future where AI enhances human well-being responsibly.",
  },
];
export default function About() {
  const [state, handleSubmit, reset] = useForm("xkndqojr");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleError = (error) => {
    toast.dismiss();
    toast.error(error.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSuccess = (message) => {
    toast.dismiss();
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  if (state.succeeded) {
    handleSuccess("Request sucessfully sent, We wil be in touch shortly");
    reset();
  }

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("https://api.apispreadsheets.com/data/0q8ljvJuPjlKX88b/", {
      method: "POST",
      body: JSON.stringify({ data: { Email: email } }),
    }).then((res) => {
      if (res.status === 201) {
        toast.success("Subscribed successfully!");
        setLoading(false);
        setEmail("");
      } else {
        toast.error("Something went wrong!");
        setLoading(false);
        setEmail("");
      }
    });
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Analytics Intelligence | About Us</title>
        <link rel="canonical" href="/about" />
        <meta
          name="description"
          content="We are a data analytics and artificial intelligence software development company. We believe that the growth of data, data analysis and generative AI, are significantly changing how organisations and government agencies will be run going into the future. We are dedicated to harnessing the power of artificial intelligence (AI) to drive transformative changes across various industries. Our product portfolio includes [AI] Platform, Data Assistant, and PLLM, catering to the unique needs of the various sectors including financial, telecommunications, and government organisations."
        />
        <meta
          name="keywords"
          content="data and technology, inclusive, simple access, quicker access, insights, data analytics, artificial intelligence, software development company, empowering businesses, Africa, unique challenges, opportunities, African market, cutting-edge solutions, specific needs, digital transformation, trusted partner, data's potential, informed decisions, growth, sustainable development, revolutionizing, data utilisation, innovative analytics, AI solutions, empowering companies, compete globally, economic development"
        />
      </Helmet>
      <main className="company-bg flex flex-col   pb-[150px] bg-[#03050B] pt-[76px]">
        <img src={Company} alt="team" />
        <div className="container py-[28px] md:py-[64px] flex flex-col gap-4 md:gap-[72px] ">
          <h3 className="md:text-[40px] text-[28px] font-[700] about-h leading-[150%]">
            About Us
          </h3>
          {aboutUs.map((about) => (
            <div className="flex flex-col my-3" key={about.title}>
              <h1 className="text-poppingBlue text-[24px] md:text-[33px]">
                {about.title}
              </h1>
              <p className="blurry-text">{about.desc}</p>
            </div>
          ))}
        </div>
        <div className="container py-[28px] md:py-[64px] ">
          <img src={Company2} alt="team" />
        </div>
        <div
          className="container py-[28px] md:py-[64px]  relative"
          id="careers"
        >
          <h1 className="max-w-[931x] mx-auto text-center text-[32px] gradient-header-alt md:text-[40px] font-semibold leading-[150%] mb-3 md:mb-6">
            Careers
          </h1>
          <p className="text-center text-[19px] font-[500] text-white">
            Find your dream role and contribute to something meaningful. Explore
            featured positions or view all openings.
          </p>
          <Careers />
          <img
            src={BG_Pop}
            alt="dots"
            className="absolute bottom-[50%] left-0"
          />
        </div>
        {/* <div className="container py-[28px] md:py-[64px] flex flex-col gap-5 md:gap-[90px] lg:gap-[20px] ">
          <div className="flex md:flex-row flex-col md:gap-[5%] gap-[30px] lg:items-center justify-between  ">
            <div className="w-full max-w-[578px] flex flex-col gap-3">
              <h3 className="md:text-[40px] text-[28px] font-[700] text-poppingBlue leading-[150%]">
                Safety & Responsibility
              </h3>
              <p className="mb-2 text-[16px] text-justify leading-8 blurry-text">
                At Analytics Intelligence [AI], safety and responsibility are
                core values guiding our work in generative AI, data analytics,
                and artificial intelligence. We prioritise rigorous testing,
                transparency, user privacy, and ongoing dialogue with experts
                and stakeholders.
              </p>
              <p className=" text-[16px] text-justify leading-8 blurry-text">
                Our commitment extends to proactive measures for risk
                mitigation, comprehensive team training, and advocacy for
                ethical standards and regulations in AI. We strive to foster
                trust, empower users, and contribute positively to the broader
                AI safety research agenda, all with the aim of creating a future
                where AI enhances human well-being responsibly.
              </p>
            </div>
            <div className="">
              <img src={Sales} alt="illustrarion" />
            </div>
          </div>
        </div> */}

        <div
          className="container py-[28px] md:py-[64px]  flex flex-col gap-6   partners-lines relative"
          id="contact-us"
        >
          <h1 className="max-w-[728px] mx-auto text-center text-[32px] gradient-header md:text-[40px] font-semibold leading-[150%]">
            Contact Us
          </h1>
          <form
            method="POST"
            onSubmit={handleSubmit}
            className="w-full max-w-[600px] mx-auto "
          >
            <div>
              <label className="text-poppingBlue text-[23px]" htmlFor="name">
                Name
              </label>
              <input
                required
                type="text"
                id="name"
                name="name"
                placeholder="Enter name"
                className="form-input bg-transparent"
              />
            </div>
            <div>
              <label className="text-poppingBlue text-[23px]" htmlFor="email">
                Email
              </label>
              <input
                required
                type="email"
                id="email"
                name="email"
                placeholder="Enter email"
                className="form-input bg-transparent"
              />
            </div>
            <div>
              <label className="text-poppingBlue text-[23px]" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Type message here"
                className="form-input bg-transparent"
                rows={6}
                required
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-3 w-max mx-auto">
              <button
                type="reset"
                className="w-max mx-auto mt-4 flex justify-center items-center px-[56px] py-4 text-white bg-dark rounded-xl"
              >
                Clear All
              </button>{" "}
              <button
                disabled={state.submitting}
                type="submit"
                role={"form"}
                className="w-max mx-auto mt-4 flex justify-center items-center px-[56px] py-4 bg-poppingBlue rounded-xl"
              >
                {state.submitting ? "Submitting" : "Submit"}
              </button>
            </div>
          </form>
        </div>
        {/* <div
          className="container flex flex-col py-[32px]  md:py-[100px]"
          id="newsletter"
        >
          <h1 className="max-w-[600x] mx-auto text-center text-[32px] gradient-header-alt md:text-[40px] font-semibold leading-[150%]  pb-5">
            Newsletter
          </h1>
          <p className="text-center text-[19px] font-[500] blurry-text">
            Stay informed about the latest developments in [AI] activities and
            news - sign up for our newsletter today!
          </p>
          <form
            className="flex w-full max-w-[700px] mx-auto  mt-8 md:mt-[64px]"
            onSubmit={(e) => handleSubscribe(e)}
          >
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email address"
              className="w-full py-5 px-10 text-black  bg-white"
            />
            <button
              type="submit"
              className="text-white  bg-poppingBlue py-5 px-10"
              disabled={loading}
            >
              {loading ? "Subscribing..." : "Subscribe"}
            </button>
          </form>
        </div> */}

        <div className="container text-center flex flex-col gap-2 py-[32px] md:py-[96px] company-bg-bottom">
          <h1 className="gradient-header text-[28px] md:text-[40px] font-semibold leading-[150%] tracking-[-0.4px]">
            [AI] is your one-stop partner for data insights
          </h1>
          <p className="px-2 w-full max-w-[900px] blurry-text mx-auto text-center font-manrope">
            Optimize your data strategy with [AI], your all-in-one assistant for
            impactful insights. Unlock success with our extensive turnkey
            applications tailored to your business, market segment or industry
            sector
          </p>
          <Link to="/demo">
            <button className="w-max mx-auto mt-4 flex justify-center items-center px-[30px] py-4 bg-poppingBlue rounded-xl text-white">
              Request a Demo
            </button>
          </Link>
        </div>
      </main>
    </>
  );
}
